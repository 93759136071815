import React from "react";
import { FlexSpacer } from "./FlexSpacer";
import ResourceShelf from "./ResourceShelf/ResourceShelf";
import styled from "styled-components";

/**
 * Constants to control Resource shelf widths
 */
const SHELF_TOGGLE_WIDTH = 45;
const SHELF_DRAWER_WIDTH = 320;

const StyledMain = styled.main<{ right: number }>`
  margin-right: ${({ right }) => right}px;
  // min-height: 100vh;
  @media only screen and (max-width: 766px) {
    margin-right: 0;
  }
`;

export const MainLayout = ({
  children,
  Header,
  Footer,
  className,
  pageSlug
}: {
  children: React.ReactNode;
  Header: React.FC;
  Footer: React.FC;
  className?: string;
  pageSlug?: string;
}) => {
  const HeaderJSX = Header;
  const FooterJSX = Footer;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <HeaderJSX />
      <div style={{ position: "relative" }}>
        <StyledMain className={className} right={SHELF_TOGGLE_WIDTH}>
          {children}
        </StyledMain>
        <ResourceShelf
          togglewidth={SHELF_TOGGLE_WIDTH}
          drawerwidth={SHELF_DRAWER_WIDTH}
          pageSlug={pageSlug}
        />
      </div>
      <FlexSpacer />
      <FooterJSX />
    </div>
  );
};
