import * as React from 'react';
import styled from 'styled-components'
import { FiMenu } from 'react-icons/fi'
 
const Wrap = styled.div`
  * {
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const StyledButton = styled.button`
  border: none;
  background: none;
`
 
const BurgerButton = ({ toggleBurger }) => {
  return (
    <Wrap>
      <StyledButton onClick={() => toggleBurger()} >
        <FiMenu size="2rem" />
      </StyledButton>      
    </Wrap>
  )
}
 
export default BurgerButton