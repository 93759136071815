import * as React from "react";
import { WmkLink } from "wmk-link";
import styled from "styled-components";
import { colors } from "../../../../vars/palette";
import { ButtonLink } from "../../../../classes/ButtonLink";

const AskContainer = styled.div`
  background-color: ${colors.green.hex};
  box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.77);
  margin-top: 14px;
  text-align: center;
  width: 76px;
  padding: 8px 0;
  line-height: 1;
  box-sizing: border-box;

  a {
    text-decoration: none;
    color: ${colors.white.hex};
    font-size: 12px;
    line-height: 1;
  }
`;

/**
 */
const ShelfButton = ({ button }: { button: ButtonLink }) => {
  const { to, target, text } = button;
  return (
    <AskContainer>
      <WmkLink to={to} target={target}>
        {text}
      </WmkLink>
    </AskContainer>
  );
};

export default ShelfButton;
