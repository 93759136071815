import * as React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import MegaMenu from "./MegaMenu/MegaMenu";
import BurgerButton from "./Burger/BurgerButton";
import Share from "./Share";
import StaticLogo from "../StaticLogo";
import { SearchButton } from "./Search/Search";
import MegaCabinet from "./MegaMenu/MegaCabinet";
import { MAINBAR_BURGER_BREAK } from "../Header";
import { Menu } from "../../../../classes/Menu";
// import DipraLogo from "../../../../images/svg/DipraLogo";

const Wrap = styled.div`
  width: 100%;
  .top {
    position: relative;
    z-index: 10000;
    background: white;
    box-shadow: 5px 0px 8px rgba(0, 0, 0, 0.25) !important;
  }
  .padded {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .vert-center {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;
const ShareSearchWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media print {
    display: none;
  }
`;

/**
 * @component
 * @param {Menu} megaMenu
 * @param {string} siteTitle
 * @returns {JSX}
 */
const HeaderMainBar = ({
  megaMenu,
  topbarMenu
}: {
  megaMenu: Menu;
  topbarMenu: Menu;
}) => {
  const [viewWidth, setViewWidth] = useState(0);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);

  const viewRef = useRef();

  const toggleBurger = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  const toggleSearch = () => setIsSearchOpen(!isSearchOpen);

  const toggleShare = () => setIsShareOpen(!isShareOpen);

  const [currMenu, setCurrMenu] = useState(null);
  const updateCurrMenu = useCallback(
    (menu: Menu) => {
      if (menu === currMenu) {
        setCurrMenu(null);
      } else {
        setCurrMenu(menu);
      }
    },
    [currMenu]
  );

  useEffect(() => {
    const resize = () => {
      setViewWidth(viewRef.current.getBoundingClientRect().width);
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [setViewWidth]);
  return (
    <>
      <Wrap ref={viewRef}>
        <Container fluid className="top">
          <Row>
            <Col>
              <Container className="padded">
                <Row>
                  <Col xs={4} xl={2} lg={2} md={2} className="vert-center">
                    {/* <DipraLogo linked /> */}
                    <StaticLogo />
                  </Col>
                  {viewWidth > MAINBAR_BURGER_BREAK ? (
                    <>
                      <Col xl={10} lg={10} md={10} className="vert-center">
                        <MegaMenu
                          menu={megaMenu}
                          updateCurrMenu={updateCurrMenu}
                        />

                        <ShareSearchWrap>
                          <Share toggle={toggleShare} open={isShareOpen} />
                          <SearchButton
                            isSearchOpen={isSearchOpen}
                            setIsSearchOpen={setIsSearchOpen}
                            // toggle={isSearchOpen}
                            // handler={toggleSearch}
                          />
                        </ShareSearchWrap>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={2} sm={4} md={6} />
                      <Col xs={1} sm={1} md={1}>
                        <Share toggle={toggleShare} open={isShareOpen} />
                      </Col>
                      <Col xs={4} sm={2} md={1}>
                        <BurgerButton toggleBurger={toggleBurger} />
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <MegaCabinet
          viewWidth={viewWidth}
          megaMenu={megaMenu}
          topbarMenu={topbarMenu}
          toggleBurger={toggleBurger}
          isBurgerOpen={isBurgerOpen}
          currMenu={currMenu}
          updateCurrMenu={updateCurrMenu}
          searchToggle={isSearchOpen}
          searchHandler={toggleSearch}
        />
      </Wrap>
    </>
  );
};

export default HeaderMainBar;
