import * as React from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../../../vars/palette";
import { IoClose } from "react-icons/io5";
import { ShelfIconMain } from "../Icons/ShelfIconMain";
import { useState } from "react";
import { useCookies } from "react-cookie";
import * as dayjs from "dayjs";

const ToggleClose = styled.div`
  position: absolute;
  z-index: 20;
  top: -0.65rem;
  right: -0.65rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white.hex};
  border-radius: 100%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
  :hover {
    cursor: pointer;
  }
`;

const MainToggle = styled.button`
  position: relative;
  background-color: ${colors.darkBlue.hex};
  color: ${colors.darkBlue.hex};
  font-weight: 600;
  height: 102px;
  padding: 0 9px;
  border: none;
  .resources {
    background-color: ${colors.darkGray.hex};
    transform-origin: 14px -8px;
    transform: rotate(90deg);
    padding: 7px 8.25px;
    position: absolute;
    left: -6px;
    top: 22px;
    span {
      display: block;
    }
  }
`;

const fadeIn = keyframes`
0% { opacity: 0; }
70% { opacity: 0;}
100%  { opacity: 1; }`;

const StyledToggleHelp = styled.div`
  width: 16rem;
  position: absolute;
  top: -143%;
  background: ${colors.green.hex};
  color: white;
  padding: 1.5rem 1rem;
  right: 150%;
  border-radius: 0.5rem;
  &:after {
    content: "";
    position: absolute;
    bottom: -18%;
    right: 0;
    background: transparent;
    border-top: 1.5rem solid transparent;
    border-bottom: 1.5rem solid transparent;
    border-right: 1.5rem solid ${colors.green.hex};
    border-left: 1.5rem solid transparent;
    width: 0;
    height: 0;
    z-index: -1;
  }
  opacity: 1;
  //box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  animation-name: ${fadeIn};
  animation-duration: 3s;
  animation-iteration-count: 1;
  @media only screen and (max-height: 750px) and (min-width: 768px) {
    top: auto;
    bottom: -143%;
    &::after {
      bottom: auto;
      top: -18%;
    }
  }
  @media only screen and (max-width: 767px) {
    top: -240%;
    right: 26%;
    &::after {
      right: 62%;
    }
  }
  @media only screen and (max-width: 576px) {
    top: -240%;
    right: 14%;
  }
`;

const StyledHelpClose = styled.div`
  &:hover {
    cursor: pointer;
  }
  position: absolute;
  top: 0;
  right: 0.25rem;
`;

export const ToggleHelp = () => {
  const [helpIsOpen, setHelpIsOpen] = useState(true);
  const [cookies, setCookie] = useCookies(["returningUser"]);

  const handleClose = () => {
    const expirationDate = dayjs().add(1, "month").toDate();
    setCookie(
      "returningUser",
      { isReturningUser: true },
      { path: "/", expires: expirationDate }
    );
    setHelpIsOpen(false);
  };
  return helpIsOpen && !cookies.returningUser?.isReturningUser ? (
    <StyledToggleHelp>
      <span>
        Check out the Resources Tab for more relevant content for each page.
      </span>
      <StyledHelpClose>{<IoClose onClick={handleClose} />}</StyledHelpClose>
    </StyledToggleHelp>
  ) : null;
};

export const Toggle = ({
  click,
  isOpen,
  showHelp
}: {
  click: () => void;
  isOpen: boolean;
  showHelp?: boolean;
}) => {
  return (
    <div style={{ position: "relative" }}>
      {!isOpen ? <ToggleHelp /> : null}
      <MainToggle onClick={click}>
        <div className="resources">
          <span>Resources</span>
        </div>
        <ShelfIconMain />
        {isOpen && (
          <ToggleClose>
            <IoClose size="18px" color="#808080" />
          </ToggleClose>
        )}
      </MainToggle>
    </div>
  );
};
