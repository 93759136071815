export type ColorKeys = keyof typeof colors

/**
 * @object
 * A simple object to represent theme colors.
 */
export const colors = {
  green: { hex: "#84C447", r: 0, g: 196, b: 71 },
  lightGreen: { hex: "#A2CD4D", r: 162, g: 205, b: 77 },
  blue: { hex: "#00AFD8", r: 0, g: 175, b: 216 },
  lightBlue: { hex: "#ADDFF0", r: 173, g: 223, b: 240 },
  grayBlue: { hex: "#1F3E4F", r: 31, g: 62, b: 79 },
  darkBlue: { hex: "#001743", r: 0, g: 23, b: 67 },
  darkGray: { hex: "#EAEAEA", r: 234, g: 234, b: 234 },
  darkerGray: { hex: "#394A58", r: 57, g: 74, b: 88 },
  text: { hex: "#394A58", r: 57, g: 74, b: 88 },
  white: { hex: "#fefefe", r: 254, g: 254, b: 254 },
  black: { hex: "#262626", r: 38, g: 38, b: 38 },
  techNavGreen: { hex: `#7AB800`, r: 122, g: 184, b: 0 },
  skyBlue: {
    hex: "#F1F8FB",
    r: 241,
    g: 248,
    b: 251,
    alpha: `rgba(0, 175, 216, 0.075)`
  }
};
