import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import { WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";

const Wrap = styled.div`
  margin: 2rem 0 2rem;
  .container {
    padding: 0 0 2rem !important;
    border-bottom: 1px solid ${colors.white.hex};
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
const MenuCol = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  & > div {
    &:first-of-type a {
      color: ${colors.green.hex} !important;
      font-weight: bold;
      font-size: 0.925rem;
      margin-bottom: 1rem;
    }
    a {
      color: ${colors.white.hex};
      text-decoration: none;
      font-size: 0.85rem;
      margin-bottom: 0.65rem;
      transition: all 0.3s ease;
      &:hover {
        color: ${colors.blue.hex};
        text-decoration: underline;
        transition: all 0.3s ease;
        text-decoration: underline !important;
      }
    }
  }
`;

const FooterMainMenu = ({ menu }) => {
  const menuItems = get(menu, "links");

  return (
    <Wrap>
      <Container>
        <Row>
          {menuItems.map((item, i) => {
            const parent = get(item, "parent");
            const links = get(item, "links");
            return (
              <MenuCol lg={2} key={"menu" + i}>
                <WmkLink to={parent.to} target={parent.target}>
                  {parent.text}
                </WmkLink>
                {links.map((link, j) => (
                  <WmkLink
                    key={link.text + j}
                    to={link.to}
                    target={link.target}>
                    {link.text}
                  </WmkLink>
                ))}
              </MenuCol>
            );
          })}
        </Row>
      </Container>
    </Wrap>
  );
};

export default FooterMainMenu;
