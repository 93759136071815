import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";

import ShelfDrawer from "./ShelfDrawer";
import get from "lodash/get";
import { PdfAsset, PdfAssets } from "../../../classes/PdfAsset";
import { YoutubeVideo, YoutubeVideos } from "../../../classes/YoutubeVideo";
// import { BREAKPOINT_MEDIUM_MAX, FONT_WEIGHT_SEMI_BOLD } from "../../ui/Typography";
import { Fragment } from "react";
import FixedResourceShelf from "./FixedResourceShelf";
import { ToggleHelp } from "./Toggles/ToggleButton";
import { Toggles } from "./Toggles/Toggles";


/**
 * @styled
 * CSS and reactive styling for the Shelf toggle
 * button background (blue angled bar)
 */
// const ToggleBackground = styled.div`
//   background-color: ${colors.blue.hex};
//   height: 100vh;
//   width: ${({ togglewidth }) => `${togglewidth}px`};
//   box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.25);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

/**
 * @styled
 * Container for buttons on the toggle bar
 */
// const ToggleButtons = styled.div`
//   position: relative;
//   padding-right: 26px;
// `;

/**
 * @styled
 * Styled component for main shelf toggle button
 */
// const MainToggle = styled.button`
//   position: relative;
//   background-color: ${colors.darkBlue.hex};
//   color: ${colors.darkBlue.hex};
//   font-weight: 600;
//   height: 102px;
//   padding: 0 9px;
//   border: none;

//   .resources {
//     background-color: ${colors.darkGray.hex};
//     transform-origin: 14px -8px;
//     transform: rotate(90deg);
//     padding: 7px 8.25px;
//     position: absolute;
//     left: -6px;
//     top: 22px;
//     span {
//       display: block;
//     }
//   }
// `;
// const ToggleClose = styled.div`
//   position: absolute;
//   z-index: 20;
//   top: -0.65rem;
//   right: -0.65rem;
//   width: 1.25rem;
//   height: 1.25rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: ${colors.white.hex};
//   border-radius: 100%;
//   box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
//   :hover {
//     cursor: pointer;
//   }
// `;

/**
 * @styled
 * CSS and reactive styling for the Shelf drawer
 */
const Shelf = styled.div`
  @media print {
    display: none;
  }
  position: fixed;
  bottom: 0;
  right: ${({ open, drawerwidth }: { open: boolean, drawerwidth:number, togglewidth: number }) => (open ? 0 : `-${drawerwidth}px`)};
  z-index: 200;
  height: 100vh;
  width: ${({ togglewidth, drawerwidth }) => `${togglewidth + drawerwidth}px`};
  display: flex;
  transition: all 0.3s ease;
`;

/**
 * @component - Button that handles open/close toggle state
 * @param {function} click - click toggle state handler
 * @returns {JSX}
 */
// const Toggle = ({ click, isOpen } : {click: Function, isOpen: boolean}) => {
//   return (
//     <MainToggle onClick={click}>
//       <div className="resources">
//         <span>Resources</span>
//       </div>
//       <ShelfIconMain />
//       {isOpen && (
//         <ToggleClose>
//           <IoClose size="18px" color="#808080" />
//         </ToggleClose>
//       )}
//     </MainToggle>
//   );
// };

/**
 * @component = Renders 'resource shelf', which shows helpful links plus
 * related pdf/video content depending on the page it is rendered on
 * @param {number} togglewidth - controls size of toggle bar
 * @param {number} drawerwidth - controls size of slideout drawer
 * @param {string} pageSlug  - used to determine what media content is related to page
 * @returns {JSX}
 */
const ResourceShelf = ({ togglewidth, drawerwidth, pageSlug } : { togglewidth: number, drawerwidth: number, pageSlug: string }) => {
  /**
   * Store state for interactive elements
   */
  const [isOpen, setIsOpen] = useState(false); // use to check if drawer is open or closed
  const handleToggle = () => setIsOpen(!isOpen); // sets opposite of current open state when called
  const data = useStaticQuery(query);
  const [isBreakpointSmall, setBreakpoint] = useState(null);
  /**
   * Get structured data
   */
  const pdfs = new PdfAssets(get(data, `pdf.edges`));
  const videos = new YoutubeVideos(get(data, `vid.edges`));

  /**
   * Determine what content matches current page
   */
  const _pdfMatch = pdfs.getMatch(pageSlug);
  const pdfMatch = _pdfMatch ? _pdfMatch : null;
  const _vidMatch = videos.getMatch(pageSlug);
  const vidMatch = _vidMatch ? _vidMatch : null;
  /**
   * Sort vid and pdf arrays by title alphabetically
   */
  const compare = (a: PdfAsset, b: YoutubeVideo) => {
    if (a.title < b.title) {
      return -1;
    } else if (a.title > b.title) {
      return 1;
    } else {
      return 0;
    }
  };
  const pdfMatchSorted = pdfMatch.sort(compare);
  const vidMatchSorted = vidMatch.sort(compare);

  useEffect(() => {
    const resizeEvent = () => {
      setBreakpoint(window.innerWidth < 767);
    };

    if (!isBreakpointSmall) {
      resizeEvent();
    }

    window.addEventListener("resize", resizeEvent);

    return () => {
      window.removeEventListener("resize", resizeEvent);
    };
  }, [isBreakpointSmall]);

  useEffect(() => {}, [pageSlug]);
  return (
    <Fragment>
      {isBreakpointSmall ? (
        <>
          <FixedResourceShelf
            Help={() => <ToggleHelp />}
            Shelf={() => (
              <ShelfDrawer
                isBreakpointSmall={isBreakpointSmall}
                drawerwidth={drawerwidth}
                pdfs={pdfMatchSorted}
                videos={vidMatchSorted}
              />
            )}
          />
          <ToggleHelp />
        </>
      ) : (
        <Shelf
          open={isOpen}
          togglewidth={togglewidth}
          drawerwidth={drawerwidth}>
          <Toggles
            togglewidth={togglewidth}
            handleToggle={handleToggle}
            isOpen={isOpen}
          />
          <ShelfDrawer
            drawerwidth={drawerwidth}
            pdfs={pdfMatchSorted}
            videos={vidMatchSorted}
          />
        </Shelf>
      )}
    </Fragment>
  );
};

export default ResourceShelf;

/**
 * Query for GraphQL API Data
 */
const query = graphql`
  {
    pdf: allContentfulDocument {
      edges {
        node {
          ...NodeDocumentFields
        }
      }
    }
    vid: allYoutubeVideo {
      edges {
        node {
          ...NodeYoutubeFields
        }
      }
    }
  }
`;
