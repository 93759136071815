import get from "lodash/get";
import { Img } from "./Img";
import { slugifyTitle, Tag } from "wmk-tag";
import { YouTubeFields } from "../fragments/NodeYouTubeFields";
import { startCase } from "lodash";

export const tagify = (description: string) => {
  const hashTags = description.match(/#[A-Za-z0-9_-]+/g);
  return Array.isArray(hashTags)
    ? hashTags.map((hash) => {
        const _slug = slugifyTitle(startCase(hash));
        const _name = hash.slice(1, hash.length).replace("-", " ");
        const slug = _slug === "about" ? "about-ductile-iron-pipe" : _slug;
        const name = _name === "about" ? "About Ductile Iron Pipe" : _name;
        return new Tag({
          name,
          slug
        });
      })
    : [];
};

export class YoutubeVideo {
  title: string;
  videoId: string;
  description: string;
  thumbnail: Img | undefined;
  timestamp: string;
  date: Date;
  tags: Tag[];
  constructor(node: YouTubeFields) {
    const gatsbyImageData = get(
      node,
      `localThumbnail.childImageSharp.gatsbyImageData`
    );
    const title = get(node, `title`);
    const timestamp = get(node, `publishedAt`);
    const description = get(node, `description`);
    this.title = title;
    this.videoId = get(node, `videoId`);
    this.description = description;
    this.thumbnail = gatsbyImageData
      ? new Img({ gatsbyImageData, title })
      : undefined;
    this.timestamp = timestamp;
    this.date = new Date(this.timestamp);
    this.tags = tagify(description);
  }
}

interface VideoList {
  slug: string;
  name: string;
  list: YoutubeVideo[];
}

export class YoutubeVideos {
  list: YoutubeVideo[];
  length: number;
  tagHash: {
    [key: string]: VideoList;
  };
  constructor(edges: { node: YouTubeFields }[]) {
    const videos = Array.isArray(edges)
      ? edges.map((e) => new YoutubeVideo(e.node))
      : [];
    const allObj: { [key: string]: YoutubeVideo[] } = {};
    this.list = videos;
    this.length = videos.length;
    const tags = videos.reduce((all, vid) => {
      vid.tags.forEach((tag) => {
        const { name, slug } = tag;
        if (!all[slug]) {
          all[slug] = { slug, name, list: [] } as VideoList;
        }
        const list = all[slug].list;
        list.push(vid);
      });

      return all;
    }, allObj);
    this.tagHash = tags;
  }
  getMatch(slug: string) {
    const tagFromSlug = slug.replace("/", "");
    const hash = get(this, `tagHash[${tagFromSlug}]`);
    const list = get(hash, `list`);
    const match = Array.isArray(list) ? hash.list : [];
    return match;
  }
}

