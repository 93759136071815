import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../vars/palette";
import StrengthAndDurability from "./StrengthAndDurability";

//const FOOTER_BREAK_POINT = 1200;

const Wrap = styled.div`
  .container {
    padding: 3rem 0 0 !important;
  }
  .mobile-only {
    color: ${colors.white.hex};
    margin-bottom: 1rem;
    p {
      margin-bottom: 0.25rem;
    }
    a {
      color: ${colors.white.hex};
      text-decoration: none;
      display: block;
      margin-bottom: 0.25rem;
    }
  }
  @media only screen and (min-width: 1200px) {
    .mobile-only {
      display: none;
    }
  }
`;

const FooterBottomLink = styled.span`
  a {
    margin-right: 2rem;
    color: ${colors.darkGray.hex};
    text-decoration: underline;
    transition: all 0.3s ease;
    ${({ mini }) => (mini ? `font-size: .8rem; margin-left: .5rem;` : ``)}
    &:hover {
      transition: all 0.3s ease;
      color: ${colors.blue.hex};
    }
  }
`;

const LinksCol = styled(Col)`
  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      margin: 0.5rem 0 !important;
      text-decoration: none;
    }
  }
`;
const SocialsCol = styled(Col)`
  a {
    margin: 0 0.75rem;
    svg {
      color: ${colors.white.hex};
    }
  }
`;

const FooterBottomRow = ({ menu, socials }) => {
  const links = get(menu, "links");
  return (
    <Wrap>
      <Container>
        <Row className="mobile-only">
          <Col>
            <p>P.O. Box 190306</p>
            <p>Birmingham, AL 35219</p>
            <WmkLink tel>205.402.8700 Tel</WmkLink>
            <WmkLink mailto>Email: info@dipra.org</WmkLink>
          </Col>
        </Row>
        <Row>
          <LinksCol xl={5}>
            {links.map((link, i) => (
              <FooterBottomLink key={link.text + i} mini>
                <WmkLink to={link.to} target={link.target}>
                  {link.text}
                </WmkLink>
              </FooterBottomLink>
            ))}
          </LinksCol>
          <Col xl={5}>
            <StrengthAndDurability />
          </Col>
          <SocialsCol xl={2}>
            {socials.list.map((social, i) => social.link("footer"))}
            {/* socials here when I figure out how to use the rendering from the class */}
          </SocialsCol>
        </Row>
      </Container>
    </Wrap>
  );
};

export default FooterBottomRow;
