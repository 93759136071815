import * as React from "react";
import PropTypes from "prop-types";
import HeaderTopBar from "./TopBar";
import HeaderMainBar from "./MainBar/MainBar";

export const MAINBAR_BURGER_BREAK = 1199;
export const MAINBAR_MOBILE_BREAK = 740;

/**
 * @commponent
 * @param {string} siteTitle
 * @param {Menu} megaMenu
 * @param {Menu} topbarMenu
 * @returns
 */
const Header = ({ siteTitle, megaMenu, topbarMenu }) => {
  return (
    <div style={{ position: "relative" }}>
      <HeaderTopBar
        menu={topbarMenu}
        mobile={false}
      />
      <HeaderMainBar
        megaMenu={megaMenu}
        topbarMenu={topbarMenu}
        siteTitle={siteTitle}
      />
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
