import * as React from "react";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import FooterMainMenu from "./MainMenu";
import FooterBottomRow from "./BottomRow/BottomRow";
import Copyright from "./Copyright";
import StaticReverseLogo from "./StaticReverseLogo";
import { Col, Container, Row } from "react-bootstrap";
import { useNoticeAtCollection, usePivacyOptions } from "../../../context/IubendaContext";

//const FOOTER_BREAK_POINT = 1200;

const Wrap = styled.div`
  @media print {
    display: none;
  }
  padding: 2rem 0;
  background: ${colors.darkBlue.hex};
  .iubenda-link-col {
    color: ${colors.white.hex};
    font-size: 0.7rem;
    padding-top: 1rem;
    display: flex;
    justify-content: end;
    a {
      color: ${colors.white.hex};
      padding: 0 1rem;
    }
  }
  @media only screen and (max-width: 1200px) {
    text-align: center;
  }
  position: relative;
  z-index: 5;
`;

const Footer = ({ topMenu, bottomMenu, socialMedia }) => {
  const openNoticeAtCollection = useNoticeAtCollection();
  const openPivacyOptions = usePivacyOptions();
  return (
    <Wrap>
      <Container fluid style={{ maxWidth: `1216px` }}>
        <Row>
          <Col>
            <StaticReverseLogo />
          </Col>
        </Row>
      </Container>
      <FooterMainMenu menu={topMenu} />
      <FooterBottomRow menu={bottomMenu} socials={socialMedia} />
      <Container style={{ padding: 0 }}>
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
          <Col xs={"auto"} style={{ padding: 0 }}>
            <Copyright />
          </Col>
          <Col className="iubenda-link-col">
            {/* <a
              href="https://www.iubenda.com/privacy-policy/56425644"
              class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Privacy Policy ">
              Privacy Policy
            </a> */}
            {/* <a
              href="https://www.iubenda.com/privacy-policy/56425644/cookie-policy"
              class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
              title="Cookie Policy ">
              Cookie Policy
            </a> */}
            <a
              href="#"
              // className="iubenda-cs-preferences-link"
              onClick={() => openPivacyOptions()}>
              Privacy Policy
            </a>
            <a href="#" 
            // className="iubenda-cs-uspr-link"
            onClick={() => openNoticeAtCollection()}>
              {" "}
              Notice at Collection
            </a>
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default Footer;
