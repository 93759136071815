import * as React from "react";

const TwitterXIcon = () => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 336.6 323.8"
        // style="enable-background:new 0 0 336.6 323.8;"
        style={{ height: "16px", width: "16px" }}
        xmlSpace="preserve">
        {/* <style type="text/css">
	.st0{fill:#FFFFFF;}
</style> */}
        <path
					fill="#FFF"
          className="st0"
          d="M292.5,291c-4.1-6-8.2-11.9-12.3-17.9c-15.2-22.2-30.5-44.5-45.7-66.7c-15.1-22.1-30.3-44.1-45.5-66.2
	c-1.1-1.5-1.1-2.6,0.2-4.1c4.9-5.5,9.6-11.1,14.4-16.7c15.1-17.6,30.2-35.1,45.3-52.7c11.6-13.5,23.1-27,34.7-40.4
	c0.5-0.5,1.3-1.4,1.1-1.8c-0.4-0.6-1.5-0.5-2.3-0.5c-5.8,0-11.5,0-17.3-0.1c-2,0-3.3,0.6-4.5,2.1c-4.7,5.6-9.5,11.2-14.3,16.7
	c-22.3,26-44.7,52-67,78.1c-1.7,1.9-2.4,1.5-3.6-0.3c-4.4-6.6-9-13.2-13.5-19.8c-17.1-24.9-34.2-49.8-51.2-74.8
	c-1-1.5-2.1-2.1-3.9-2.1c-24.3,0-48.6,0.1-72.9,0c-1.4,0-1.9-0.1-2.1,0.3c-0.4,0.7,0.3,1.7,0.8,2.3c13.5,19.7,27,39.4,40.6,59.1
	c19.7,28.7,39.3,57.4,59,86c1.1,1.6,1,2.6-0.3,4.1c-13.3,15.3-26.5,30.7-39.7,46.1c-13.1,15.2-26.2,30.5-39.2,45.7
	c-6.8,8-13.7,15.9-20.5,23.9c-0.4,0.5-1.3,1.3-0.8,1.8c0.3,0.4,1.2,0.3,1.8,0.3c6,0,11.9,0,17.9,0.1c1.8,0,3.1-0.5,4.3-2
	c2.8-3.6,5.9-7,8.8-10.4c14.7-17.1,29.3-34.1,44-51.2c11.2-13,22.4-26.1,33.6-39.1c1.4-1.6,2.1-1.8,3.4,0.1
	c6.9,10.2,13.9,20.2,20.9,30.3c16,23.3,32,46.6,47.9,70c1.1,1.6,2.3,2.3,4.3,2.3c12-0.1,24,0,36,0c12.2,0,24.4,0,36.6,0
	c0.8,0,2,0.1,2.3-0.4C293.7,292.6,293,291.7,292.5,291z M261.4,276.7c-0.6,0-1.6,0-2.4,0c-4.9,0-9.9,0-14.8,0c0,0,0,0,0,0
	c-5,0-10.1-0.1-15.1,0c-1.8,0-2.9-0.6-3.9-2c-7-10.2-14.1-20.3-21.2-30.4c-8.7-12.5-17.5-25-26.2-37.5
	c-19.4-27.8-38.8-55.6-58.2-83.5C109,108.1,98.4,92.8,87.7,77.6c-7.8-11.1-16-21.9-23.3-33.4c-1.4-2.1-2-2.7-0.9-2.7
	c0.7,0,1.4,0,2.3,0c9.8,0,19.5,0,29.3-0.1c2.2,0,3.6,0.7,4.8,2.5c7.2,10.5,14.5,20.9,21.8,31.4c12.9,18.5,25.9,37,38.8,55.5
	c10.3,14.8,20.6,29.6,30.9,44.4c14.5,20.7,29,41.4,43.5,62.1c8.5,12.2,17.4,24.1,25.5,36.6C261.7,276,262.3,276.7,261.4,276.7z"
        />
      </svg>
    </>
  );
};

export default TwitterXIcon;
