import * as React from "react";
import { colors, ColorKeys } from "../../vars/palette";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { Form } from "react-bootstrap";

const FONT_WEIGHT_BOLD = 700;
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_LIGHT = 100;
const FONT_WEIGHT_SEMI_BOLD = 600;

const FONT_SIZE_H1 = 16 * 3;
const FONT_SIZE_H2 = 56;
const FONT_SIZE_H3 = 44;
const FONT_SIZE_H4 = 34;
const FONT_SIZE_H5 = 29;
const FONT_SIZE_H6 = 14;
const FONT_SIZE_P = 16;
const FONT_SIZE_HEADLINE = 20;
const FONT_SIZE_TECHRES_NAV_HEADER = 3 * 16;

const remCalc = (pixels: number) => {
  return parseFloat((pixels / 16).toFixed(2));
};

export const BREAKPOINT_XL_MIN = `${remCalc(1199)}rem`; //"1199px";
export const BREAKPOINT_LARGE_MAX = `${remCalc(991)}rem`; //"1199px";
export const BREAKPOINT_MEDIUM_MAX = `${remCalc(767)}rem`; // "767px";
export const BREAKPOINT_SMALL_MAX = `${remCalc(575)}rem`; // "575px";

export const ValidatedMarkdown = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return typeof children === "string" ? (
    <ReactMarkdown>{children}</ReactMarkdown>
  ) : (
    <>{children}</>
  );
};

const HeroTitle = ({
  children,
  className,
  style,
  color,
  as
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  color?: ColorKeys;
  as?: AsElement;
}) => {
  const _color =
    typeof color === "string" && color in colors
      ? colors[color].hex
      : colors["darkBlue"].hex;
  const baseStyle = {
    //fontSize: `${remCalc(FONT_SIZE_H1)}rem`,
    fontWeight: FONT_WEIGHT_BOLD,
    color: _color
  };
  const Heading = headings[as] || headings[`h1`];
  return (
    <Heading className={className} style={{ ...baseStyle, ...style }}>
      {children}
    </Heading>
  );
};

const SubHeader2 = ({
  children,
  className,
  style,
  color,
  as
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  color?: ColorKeys;
  as?: AsElement;
}) => {
  const _color =
    typeof color === "string" && color in colors
      ? colors[color].hex
      : colors["darkBlue"].hex;
  const baseStyle = {
    fontSize: `${remCalc(FONT_SIZE_H4)}rem`,
    fontWeight: FONT_WEIGHT_BOLD,
    color: _color
  };
  const Heading = headings[as] || headings["h4"];
  return (
    <Heading className={className} style={{ ...baseStyle, ...style }}>
      {children}
    </Heading>
  );
};

const H2 = ({
  children,
  className,
  style,
  color
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  color?: ColorKeys;
}) => {
  const _color =
    typeof color === "string" && color in colors
      ? colors[color].hex
      : colors["darkBlue"].hex;
  const baseStyle = {
    fontWeight: FONT_WEIGHT_BOLD + "",
    textTransform: "uppercase" as const,
    fontSize: `${remCalc(FONT_SIZE_H5)}rem`,
    color: _color
  };
  return (
    <h5 className={className} style={{ ...baseStyle, ...style }}>
      {children}
    </h5>
  );
};

const H3 = ({
  children,
  className,
  style,
  color
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  color?: ColorKeys;
  as?: AsElement;
}) => {
  const _color =
    typeof color === "string" && color in colors
      ? colors[color].hex
      : colors["darkBlue"].hex;
  const baseStyle = {
    fontWeight: FONT_WEIGHT_REGULAR,
    textTransform: "uppercase" as const,
    fontSize: `${remCalc(FONT_SIZE_H5)}rem`,
    color: _color
  };
  return (
    <h5 className={className} style={{ ...baseStyle, ...style }}>
      {children}
    </h5>
  );
};

const EyebrowTitle = ({
  children,
  className,
  style,
  color
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  color?: ColorKeys;
}) => {
  const _color =
    typeof color === "string" && color in colors
      ? colors[color].hex
      : colors["darkBlue"].hex;
  const baseStyle = {
    fontSize: `${remCalc(FONT_SIZE_H6)}rem`,
    color: _color
  };
  return (
    <h6 className={className} style={{ ...baseStyle, ...style }}>
      {children}
    </h6>
  );
};

const P = ({
  children,
  className,
  style,
  color
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  color?: ColorKeys;
}) => {
  const _color =
    typeof color === "string" && color in colors
      ? colors[color].hex
      : colors["text"].hex;
  const baseStyle = {
    fontSize: `${remCalc(FONT_SIZE_P)}rem`,
    color: _color,
    lineHeight: 1.8125,
    fontWeight: 400
  };
  return (
    <StyledP className={className} style={{ ...baseStyle, ...style }}>
      <ValidatedMarkdown>{children}</ValidatedMarkdown>
    </StyledP>
  );
};

export const Type = {
  HeroTitle,
  H1: HeroTitle,
  LargeTitle: ({
    children,
    className,
    style,
    bold,
    color
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: ColorKeys;
    bold: boolean;
  }) => {
    const _color =
      typeof color === "string" && color in colors
        ? colors[color].hex
        : colors["darkBlue"].hex;
    const baseStyle = {
      textTransform: "uppercase" as const,
      fontSize: `${remCalc(FONT_SIZE_H2)}rem`,
      fontWeight: bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR,
      color: _color
    };
    return (
      <h2 className={className} style={{ ...baseStyle, ...style }}>
        {children}
      </h2>
    );
  },
  SubHeader: ({
    children,
    className,
    style,
    color
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: ColorKeys;
  }) => {
    const _color =
      typeof color === "string" && color in colors
        ? colors[color].hex
        : colors["darkBlue"].hex;
    const baseStyle = {
      fontSize: `${remCalc(FONT_SIZE_H3)}rem`,
      fontWeight: FONT_WEIGHT_BOLD,
      color: _color
    };
    return (
      <h3 className={className} style={{ ...baseStyle, ...style }}>
        {children}
      </h3>
    );
  },
  SubHeader2,
  SecondaryTitle: SubHeader2,
  H2,
  TechResNav: ({
    children,
    className,
    style,
    color
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: ColorKeys;
  }) => {
    const _color =
      typeof color === "string" && color in colors
        ? colors[color].hex
        : colors["darkBlue"].hex;
    const baseStyle = {
      fontWeight: FONT_WEIGHT_BOLD,
      textTransform: "uppercase" as const,
      // fontSize: FONT_SIZE_TECHRES_NAV_HEADER,
      color: _color
    };
    return (
      <StyledTechResNav
        className={className}
        style={{ ...baseStyle, ...style }}>
        {children}
      </StyledTechResNav>
    );
  },
  H5: H2,
  H3,
  H6: H3,
  EyebrowTitle,
  SubTitle: EyebrowTitle,
  P,
  Body: P,
  FormLabel: ({
    children,
    className,
    style,
    color
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: ColorKeys;
  }) => {
    const _color =
      typeof color === "string" && color in colors
        ? colors[color].hex
        : colors["darkBlue"].hex;
    const baseStyle = {
      fontSize: `${remCalc(FONT_SIZE_P)}rem`,
      color: _color,
      fontWeight: FONT_WEIGHT_LIGHT
    };
    return (
      <Form.Label className={className} style={{ ...baseStyle, ...style }}>
        {children}
      </Form.Label>
    );
  },
  FormTitle: ({
    children,
    className,
    style,
    color
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: ColorKeys;
  }) => {
    const _color =
      typeof color === "string" && color in colors
        ? colors[color].hex
        : colors["techNavGreen"].hex;
    const baseStyle = {
      fontSize: `${remCalc(Math.round(FONT_SIZE_H6 * 1.4285))}rem`,
      color: _color,
      fontWeight: FONT_WEIGHT_SEMI_BOLD,
      textTransform: "uppercase" as const
    };
    return (
      <h3 className={className} style={{ ...baseStyle, ...style }}>
        {children}
      </h3>
    );
  },
  FormHeading: ({
    children,
    className,
    style,
    color
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: ColorKeys;
  }) => {
    const _color =
      typeof color === "string" && color in colors
        ? colors[color].hex
        : colors["techNavGreen"].hex;
    const baseStyle = {
      fontSize: `${remCalc(FONT_SIZE_H6)}rem`,
      color: _color,
      fontWeight: FONT_WEIGHT_SEMI_BOLD,
      textTransform: "uppercase" as const
    };
    return (
      <h4 className={className} style={{ ...baseStyle, ...style }}>
        {children}
      </h4>
    );
  },
  FormResult: ({
    children,
    className,
    style,
    color,
    value
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    color?: ColorKeys;
    value: React.CSSProperties;
  }) => {
    const _color =
      typeof color === "string" && color in colors
        ? colors[color].hex
        : colors["darkBlue"].hex;
    const baseStyle = value
      ? {
          color: colors.blue.hex,
          fontSize: `20px`,
          fontWeight: FONT_WEIGHT_SEMI_BOLD,
          textTransform: "uppercase" as const
        }
      : {
          fontSize: `${remCalc(Math.round(FONT_SIZE_P - 2))}rem`,
          color: _color,
          fontWeight: FONT_WEIGHT_SEMI_BOLD,
          textTransform: "uppercase" as const
        };
    const newStyle = { ...baseStyle, ...style };
    return (
      <span className={className} style={newStyle}>
        {children}
      </span>
    );
  },
  NewsHeadline: styled.h1`
    font-size: ${remCalc(FONT_SIZE_H3)}rem;
    color: ${colors.white.hex};
    line-height: ${remCalc(FONT_SIZE_H3)}rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem 0 1.5rem;
    @media (max-device-width: 767px) {
      font-size: ${remCalc(FONT_SIZE_H5)}rem;
    }
  `,
  NewsSubhead: styled.div`
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    text-align: center;
    color: ${colors.lightGreen.hex};
    font-size: ${remCalc(FONT_SIZE_P)}rem;
    text-transform: uppercase;
    padding-top: 2rem;
    &:hover {
      color: ${colors.blue.hex};
    }
  `,
  CardHeadline: styled.div`
    color: ${colors.darkBlue.hex};
    font-size: ${remCalc(FONT_SIZE_HEADLINE)}rem;
    line-height: 1.5rem;
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    margin-bottom: 22px;
    text-align: center;
  `,
  EngHeadline: styled.span`
    color: ${colors.darkBlue.hex};
    font-size: ${remCalc(FONT_SIZE_HEADLINE)}rem;
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    margin-bottom: 13px;
    text-transform: uppercase;
  `,
  EngButton: styled.button`
    border: none;
    background-color: white;
    width: auto;
    color: ${colors.darkBlue.hex};
    font-size: ${remCalc(FONT_SIZE_P)}rem;
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    cursor: pointer;
    padding: 0.2rem;
  `,
  EngP: styled.p`
    color: ${colors.green.hex};
    font-size: ${remCalc(FONT_SIZE_H6)}rem;
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  `,
  EngRegion: styled.span`
    color: ${colors.green.hex};
    font-size: ${remCalc(FONT_SIZE_H6)}rem;
    font-weight: ${FONT_WEIGHT_REGULAR};
    margin-bottom: 5px;
    text-transform: uppercase;
  `,
  Quote: styled.div`
    font-size: ${remCalc(FONT_SIZE_H5)}rem;
    color: ${colors.blue.hex};
    font-style: italic;
    padding-right: 2rem;
  `,
  RolloverMain: styled.div`
    align-items: center;
    color: ${colors.white.hex};
    display: flex;
    font-size: ${remCalc(FONT_SIZE_H4)}rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    height: 100%;
    justify-content: center;
    padding-right: 18%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
      font-size: ${remCalc(FONT_SIZE_HEADLINE)}rem;
      justify-content: space-between;
      padding-left: 30px;
    }
  `,
  RolloverHeadline: styled.div`
    color: ${colors.blue.hex};
    font-size: ${remCalc(FONT_SIZE_HEADLINE)}rem;
    line-height: 1;
    margin-bottom: 12px;
  `,
  IconCopyHeadline: styled.h2`
    color: ${colors.darkBlue.hex};
    font-size: ${remCalc(FONT_SIZE_H5)}rem;
    @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
      font-size: ${remCalc(FONT_SIZE_HEADLINE)}rem;
    }
  `,
  IconCardHeadline: styled.h3`
    color: ${colors.darkBlue.hex};
    font-size: ${remCalc(FONT_SIZE_HEADLINE - 3)}rem;
    line-height: 1.5rem;
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    text-transform: uppercase;
    margin-bottom: 22px;
    text-align: center;
    @media only screen and (max-width: ${remCalc(550)}rem) {
      margin-bottom: 20px;
    }
  `,
  IconCardP: styled.span`
    color: ${colors.darkerGray.hex};
    font-size: ${remCalc(FONT_SIZE_P)}rem;
  `,
  PageHeader: styled.h1`
    font-size: ${remCalc(FONT_SIZE_H4)}rem;
    color: ${colors.darkBlue.hex};
    line-height: ${remCalc(FONT_SIZE_H4)}rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1rem 0 1.5rem;
    @media (max-device-width: ${remCalc(767)}rem) {
      font-size: ${remCalc(FONT_SIZE_H5)};
    }
  `,
  PageSubhead: styled.div`
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    text-align: center;
    color: ${colors.lightGreen.hex};
    font-size: ${remCalc(FONT_SIZE_P)}rem;
    text-transform: uppercase;
    @media (max-device-width: 767px) {
      font-size: ${remCalc(FONT_SIZE_P)}rem;
    }
  `,
  ShelfCat: styled.div`
    font-size: ${remCalc(FONT_SIZE_P - 2)}rem;
  `,
  ShelfCatTitle: styled.div`
    font-size: ${remCalc(FONT_SIZE_P - 2)}rem;
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
  `
};

const StyledH1 = styled.h1`
  font-size: ${remCalc(FONT_SIZE_H1)}rem;
  @media screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    font-size: ${remCalc(FONT_SIZE_H1 * 0.8)}rem;
  }
`;

const StyledH2 = styled.h2<{ rems?: number }>`
  font-size: ${({ rems }) => rems}rem;
  @media screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    font-size: ${({ rems }) => rems * 0.8}rem;
  }
  @media screen and (max-width: ${BREAKPOINT_SMALL_MAX}) {
    font-size: ${({ rems }) => rems * 0.65}rem;
  }
`;

/**
 * Utility for variable heading element types
 */
const headings = {
  h1: StyledH1,
  h2: ({
    children,
    style,
    rems = remCalc(FONT_SIZE_H2)
  }: {
    children: React.ReactNode;
    style?: React.CSSProperties;
    rems?: number;
  }) => (
    <StyledH2 style={style} rems={rems}>
      {children}
    </StyledH2>
  ),
  h3: ({
    children,
    style
  }: {
    children: React.ReactNode;
    style?: React.CSSProperties;
  }) => <h3 style={style}>{children}</h3>,
  h4: ({
    children,
    style
  }: {
    children: React.ReactNode;
    style?: React.CSSProperties;
  }) => <h4 style={style}>{children}</h4>,
  h5: ({
    children,
    style
  }: {
    children: React.ReactNode;
    style?: React.CSSProperties;
  }) => <h5 style={style}>{children}</h5>,
  h6: ({
    children,
    style
  }: {
    children: React.ReactNode;
    style?: React.CSSProperties;
  }) => <h6 style={style}>{children}</h6>,
  div: ({
    children,
    style
  }: {
    children: React.ReactNode;
    style?: React.CSSProperties;
  }) => <div style={style}>{children}</div>,
  span: ({
    children,
    style
  }: {
    children: React.ReactNode;
    style?: React.CSSProperties;
  }) => <span style={style}>{children}</span>
};

export type AsElement = keyof typeof headings;

const StyledTechResNav = styled.h1`
  font-size: ${remCalc(FONT_SIZE_TECHRES_NAV_HEADER)}rem;
  @media screen and (max-width: 1500px) {
    font-size: ${remCalc(FONT_SIZE_TECHRES_NAV_HEADER * 0.7)}rem;
  }
`;

const StyledP = styled.div`
  a {
    color: ${colors.blue.hex};
    transition: all 0.3s ease;
    &:hover {
      color: ${colors.darkBlue.hex};
      transition: all 0.3s ease;
    }
  }
`;
