import * as React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../../../vars/palette";
import DipraButton from "../../../../../ui/DipraButton";

const CtaText = styled.span`
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${colors.darkGray.hex};
`;

const SitemapCta = () => {
  return (
    <Row className="flex-column">
      <Col>
        <CtaText>Can't find what you're looking for?</CtaText>
      </Col>
      <Col>
        <DipraButton
          color={colors.green.hex}
          link={{ text: `DIPRA Sitemap`, to: `/sitemap` }}
          textColor={colors.white.hex}
          style={{
            fontSize: `.8rem`,
            width: "100%",
            transition: `all .3s ease`,
            textTransform: "uppercase"
          }}
          hoverColor={colors.blue.hex}
        />
      </Col>
    </Row>
  );
};

export default SitemapCta;
