import * as React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import { WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";
import { MAINBAR_BURGER_BREAK } from "./Header";

const Wrap = styled.div`
  @media print {
    display: none;
  }
  position: relative;
  z-index: 10000;
  background: ${colors.blue.hex};
  .container-fluid,
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
  }
  a {
    color: ${colors.white.hex};
    font-size: 0.9rem;
    text-decoration: none;
    text-transform: uppercase;
    margin-left: 2rem;
  }
  ${({ mobile, mobileBreak }) =>
    `@media only screen and (max-width: ${mobileBreak}px) {
      background: none !important;
      .container, .container-fluid {
        display: ${mobile === true ? "flex" : "none"};
        flex-direction: column;
        align-items: center;
        padding: 3rem 0 1rem;  
        a {
          margin-left: 0;
          margin-bottom: 1rem;
          text-transform: none;
          color: rgba(${colors.white.r}, ${colors.white.g}, ${
      colors.white.b
    }, 0.8);
        }
      }
    }`}
`;

export const getSubscribeText = (text) => {
  return typeof text === "string" && text.match(/newsletter/, "i")
    ? "Subscribe"
    : text;
};

/**
 * @component - Renders the main menu navigation as a multi-step mega menu
 * @param {Menu} menu
 * @returns {JSX}
 */
const HeaderTopBar = ({ menu, mobile }) => {
  const links = get(menu, "links");
  return (
    <Wrap mobile={mobile} mobileBreak={MAINBAR_BURGER_BREAK}>
      <Container>
        {links.map((link, i) => {
          const text = getSubscribeText(get(link, `text`));
          const to = get(link, "to");
          const target = get(link, "target");
          return (
            <WmkLink to={to} target={target} key={text + i}>
              {text}
            </WmkLink>
          );
        })}
      </Container>
    </Wrap>
  );
};

export default HeaderTopBar;
