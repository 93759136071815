import * as React from "react";

const CustomSearchIcon = ({
  height = 26,
  width
}: {
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width ? width : height}
      viewBox="0 0 47 47">
      <g transform="translate(2 2)">
        <path
          d="M23.589,2.5h0A21.095,21.095,0,0,1,31.8,43.021,21.094,21.094,0,0,1,15.38,4.158,20.957,20.957,0,0,1,23.59,2.5Zm0,38.216a17.126,17.126,0,0,0,0-34.252h0a17.126,17.126,0,0,0,0,34.252Z"
          transform="translate(-4.5 -4.5)"
          fill="#394a58"
        />
        <path
          d="M35.46,37.46a1.994,1.994,0,0,1-1.414-.586L23.561,26.389a2,2,0,1,1,2.828-2.828L36.874,34.046A2,2,0,0,1,35.46,37.46Z"
          transform="translate(7.54 7.54)"
          fill="#394a58"
        />
      </g>
    </svg>
  );
};

export default CustomSearchIcon;
