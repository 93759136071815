import get from "lodash/get";
import { PdfAsset } from "./PdfAsset";

/**
 * @class
 * Object that represents exernal hyperlinks
 */
export class HyperLink {
  /**
   * @param {Object} node - result of NodeLinkFields
   */
  constructor(node) {
    const _target = get(node, `target`);
    const _text = get(node, `text`);
    const slug = get(node, `slug`);
    const path = get(node, `parentPath`, "");
    const to = get(node, `to`, get(node, `path`, `${path}/${slug}`));
    const text = _text ? _text : get(node, `title`);
    const isExternal = typeof to === "string" && to.indexOf("http") === 0;
    const target =
      _target === "New Window"
        ? "blank"
        : _target === "Same Window"
        ? "self"
        : isExternal
        ? "blank"
        : undefined;
    const sysId = get(node, `sys.contentType.sys.id`);
    const _asset = get(node, `mediaLink`);
    const asset = _asset ? new PdfAsset(_asset) : undefined;
    const assetLink = asset ? get(asset, `file.url`) : undefined;
    const resourceId = get(node, `resourceId`);
    const newsId = get(node, `newsId`);
    this.text = text || undefined;
    this.to = assetLink
      ? assetLink
      : isExternal
      ? to
      : typeof to === "string"
      ? to.replace("//", "/")
      : undefined;
    this.target = target;

    this.name = get(node, `title`, get(node, `name`));
    this.sysId = sysId;
    this.rootPath = resourceId
      ? "/technical-resources"
      : newsId
      ? "/news/news-and-commentary"
      : "";
  }
}
