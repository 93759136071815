import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { WmkLink } from "wmk-link";

const StaticLogo = () => {
  return (
    <WmkLink to="/">
      <StaticImage
        src="../../../images/logos/DIPR-Logo_RGB-01_.png"
        alt="Ductile Iron Pipe Research Association"
        width={180}
        height={90}
        placeholder={"none"}
        layout={"constrained"}
        transformOptions={{ fit: "contain" }}
        backgroundColor={"transparent"}
        quality={95}
      />
    </WmkLink>
  );
};

export default StaticLogo;
