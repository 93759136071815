import styled from "styled-components";
import { colors } from "../../../../vars/palette";

/**
 */
export const ToggleBackground = styled.div<{ togglewidth: number }>`
  background-color: ${colors.blue.hex};
  height: 100vh;
  width: ${({ togglewidth }) => `${togglewidth}px`};
  box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;
