const React = require("react");
const Layout = require("./src/components/Layout/Layout").default;

require("./node_modules/bootstrap/dist/css/bootstrap.css");
require("./node_modules/slick-carousel/slick/slick.css");
require("./src/components/ui/CardSlider/CardSlider.css");

import { GatsbyBrowser } from "gatsby";
import { CookiesProvider } from "react-cookie";
import { SearchProvider } from "./src/context/SearchContext";
import "./src/styles/global.css";
import {
  IubendaCtxProvider,
  NoticeAtCollectionHiddenLink,
  PrivacyOptionsHiddenLink
} from "./src/context/IubendaContext";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props
}) => {
  //API interface
  if (element.props.location.pathname == "/algoapi") {
    return <>{element}</>;
  }
  //regular interface
  return (
    <Layout {...props} pathName={element.props.location.pathname}>
      {element}
    </Layout>
  );
};

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element
}) => {
  return (
    <IubendaCtxProvider>
      <SearchProvider>
        <CookiesProvider>{element}</CookiesProvider>
      </SearchProvider>
      <PrivacyOptionsHiddenLink />
      <NoticeAtCollectionHiddenLink />
    </IubendaCtxProvider>
  );
};
