import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { LinkTarget, WmkLink } from "wmk-link";
import { Menu } from "../../../classes/Menu";
import { ShelfIconEmail } from "./Icons/ShelfIconEmail";
import { ShelfIconPhone } from "./Icons/ShelfIconPhone";
import { ShelfIconPdf } from "./Icons/ShelfIconPdf";
import { ShelfIconVideo } from "./Icons/ShelfIconVideo";
import { BREAKPOINT_MEDIUM_MAX, Type } from "../../ui/Typography";
import { PdfAsset } from "../../../classes/PdfAsset";
import { YoutubeVideo } from "../../../classes/YoutubeVideo";
import { IconProps } from "./Icons/ShelfIconCalc";
import { ShelfIconCalc } from "./Icons/ShelfIconCalc";
import { ButtonLink } from "../../../classes/ButtonLink";
import { colors } from "../../../vars/palette";
import { ShelfIconDocument } from "./Icons/ShelfIconDocument";

const NullComponent = () => <></>;

/**
 * @styled - CSS and reactive styles for inner shelf drawer (slideout)
 */
const Drawer = styled.div<{ drawerwidth: number }>`
  height: 100vh;
  width: ${({ drawerwidth }) => `${drawerwidth}px`};
  background: ${colors.white.hex};
  display: flex;
  align-items: center;

  .scroll-row {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .link-row {
    margin-left: 0;
    border-bottom: 1px solid ${colors.blue.hex};
    padding: 10px;
    &.quick {
      padding: 20px 0;
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    height: unset;
    box-sizing: border-box;
    padding: 0 8px;
    width: 100%;

    .scroll-row {
      width: 97%;
      height: 97%;
      margin-right: 0;
      margin-left: 0;
    }

    .link-row {
      padding: 9px 0;
    }

    .contact-row {
      padding-bottom: 1rem;
    }
  }
`;

const ScrollableCol = styled(Col)`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  //width: 90%;
  //padding: 0 14px 0 0;
  padding: 0;
  max-height: 450px;
  overflow-x: hidden;
  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */
  margin-bottom: 25px;

  &:first-child {
    margin-top: 144px;
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.green.hex};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(
      90deg,
      white 0%,
      white 39%,
      ${colors.green.hex} 40%,
      ${colors.green.hex} 60%,
      white 61%,
      white 100%
    );
  }
  svg {
    height: 40px;
    width: 40px;
    margin-left: 0;
  }
  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    padding: 0 15px;
    left: 0;
    max-height: 115px;
    width: 100%;
    flex-basis: unset;

    &:first-child {
      margin-top: 15px;
    }
  }
`;

const InsetCol = styled.div<{ noinset?: "true" }>`
  position: relative;
  left: 5%;
  width: 95%;
  ${({ noinset }) =>
    noinset
      ? ``
      : `box-shadow: inset 2px 5px 6px
    rgba(${colors.text.r}, ${colors.text.g}, ${colors.text.b}, 0.1);`}
  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    width: 100%;
  }
`;
/**
 * @constant - Object that works as a hash table to register SVG icons
 * for the quick links section
 */
const quickLinkComponents = {
  "/technical-resources/calculators": (props: IconProps) => (
    <ShelfIconCalc {...props} />
  ),
  "/news/dipra-e-newsletters/subscribe": (props: IconProps) => (
    <ShelfIconEmail {...props} />
  ),
  "/contact-us": (props: IconProps) => <ShelfIconPhone {...props} />,
  "/technical-resources/all": (props: IconProps) => (
    <ShelfIconDocument {...props} />
  )
};

/**
 */
const ShelfDrawer = ({
  drawerwidth,
  pdfs,
  videos,
  isBreakpointSmall
}: {
  drawerwidth: number;
  pdfs: PdfAsset[];
  videos: YoutubeVideo[];
  isBreakpointSmall?: boolean;
}) => {
  /**
   * Static query for quick links data (bottom three links)
   */

  const quickLinks = new Menu(useStaticQuery(query).contentfulMenu);
  return (
    <Drawer
      drawerwidth={drawerwidth} //isBreakpointSmall={isBreakpointSmall}
    >
      <Row className="flex-column scroll-row">
        {pdfs && (
          <ScrollableCol>
            <InsetCol>
              {pdfs.length ? (
                <SectionPdf pdfs={pdfs} />
              ) : (
                <SectionNull type="PDF" />
              )}
            </InsetCol>
          </ScrollableCol>
        )}
        {videos && (
          <ScrollableCol>
            <InsetCol>
              {videos.length ? (
                <SectionVid videos={videos} />
              ) : (
                <SectionNull type="video" />
              )}
            </InsetCol>
          </ScrollableCol>
        )}
        <Col className={isBreakpointSmall ? "contact-col" : "content-col"}>
          <InsetCol noinset="true">
            <SectionQuick
              links={quickLinks.links}
              isBreakpointSmall={isBreakpointSmall}
            />
          </InsetCol>
        </Col>
      </Row>
    </Drawer>
  );
};

/**
 */
const VerticalScroller = ({ links }: { links: JSX.Element[] }) => {
  return (
    <div>
      {links.map((link, i) => {
        return <div key={"vert-scroll-link" + i}>{link}</div>;
      })}
    </div>
  );
};

const StyledMediaLink = styled.div<{ center?: "true" }>`
  a,
  span {
    ${({ color }) =>
      color
        ? `color: ${color}; text-decoration: none; &:hover{ opacity: 0.8; text-decoration: none;}`
        : ``}
    ${({ center }) => (center ? `text-align: center;` : ``)}
  }
  .link-row {
    ${({ color }) =>
      color !== colors.techNavGreen.hex
        ? `background: ${colors.skyBlue.alpha};`
        : ``}
  }
`;

/**
 * @component = Renders a link
 * @param {*} param0
 * @returns
 */
const MediaLink = ({
  title,
  Icon = NullComponent,
  target,
  to,
  color
}: {
  title: string;
  Icon?: React.FC;
  target?: LinkTarget;
  to?: string;
  color: string;
}) => {
  const Inner = ({ title }: { title: string }) => {
    return (
      <Row className="link-row">
        <Col xs="auto">
          <Icon />
        </Col>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <Row className="flex-column">
            <Col>
              <Type.ShelfCatTitle>{title}</Type.ShelfCatTitle>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  return (
    <StyledMediaLink color={color}>
      {to ? (
        <WmkLink to={to} target={target}>
          <Inner title={title} />
        </WmkLink>
      ) : (
        <span>
          <Inner title={title} />
        </span>
      )}
    </StyledMediaLink>
  );
};

// const NullMediaLink = () => {
//   return <MediaLink color={`rgba(0,175,216,0.075)`} title="" />;
// };

const NullMediaMessage = ({ type, color }: { type: string; color: string }) => {
  return (
    <MediaLink
      color={color}
      title={`There are no matching ${type} resources for this page.`}
      //center="true"
    />
  );
};

/**
 */
const SectionNull = ({ type }: { type: string }) => {
  const nullData = [<NullMediaMessage type={type} color={colors.text.hex} />];
  return <VerticalScroller links={nullData} />;
};

/**
 * @component - Renders an array of PDF data as links to PDF within
 * scrollable section that shows up to three at a time
 * @param {Array} pdfs
 * @returns {JSX}
 */
const SectionPdf = ({ pdfs }: { pdfs: PdfAsset[] }) => {
  const pdfLinks = Array.isArray(pdfs)
    ? pdfs.map((pdf, i) => {
        const { title, file } = pdf;
        const { fileType } = file;
        return (
          fileType === "application/pdf" && (
            <MediaLink
              key={title + i}
              title={title}
              //category={"PDF"}
              Icon={ShelfIconPdf}
              target="blank"
              to={pdf.download}
              color={colors.darkBlue.hex}
            />
          )
        );
      })
    : [];
  return <VerticalScroller links={pdfLinks} />;
};

/**
 */
const SectionVid = ({ videos }: { videos: YoutubeVideo[] }) => {
  const videoLinks = Array.isArray(videos)
    ? videos.map((video, i) => {
        const { title, videoId } = video;
        return (
          <MediaLink
            key={title + i}
            title={title}
            //category={"Video"}
            Icon={ShelfIconVideo}
            target="blank"
            to={`http://www.youtube.com/watch?v=${videoId}`}
            color={colors.blue.hex}
          />
        );
      })
    : [];
  return <VerticalScroller links={videoLinks} />;
};

/**
 * @component - Renders static quick links to pertinent sections of the site.
 * Buttons are fixed — no scroll. Layout changes to alternate icon only layout on mobile
 * @param {Array} links - array of link data
 * @returns {JSX}
 */
const SectionQuick = ({
  links,
  isBreakpointSmall
}: {
  links: ButtonLink[];
  isBreakpointSmall?: boolean;
}) => {
  return Array.isArray(links) ? (
    <Row className={isBreakpointSmall ? "flex contact-row" : "flex-column"}>
      {links.map((link, i) => {
        const { to, text, target } = link;
        /**
         * Load registered SVG icon or null component if none exists
         */
        const IconJsx =
          quickLinkComponents[to as keyof typeof quickLinkComponents] ||
          NullComponent;

        if (isBreakpointSmall)
          return (
            <Col key={text + i} xs={4}>
              <WmkLink to={to} target={target} label={text}>
                <IconJsx title={text} />
              </WmkLink>
            </Col>
          );
        return (
          <Col key={text + i} xs={12}>
            <MediaLink
              //category={""}
              title={text}
              to={to}
              target={target}
              Icon={() => <IconJsx title={text} />}
              color={colors.techNavGreen.hex}
            />
          </Col>
        );
      })}
    </Row>
  ) : null;
};

export default ShelfDrawer;

const query = graphql`
  {
    contentfulMenu(title: { eq: "Shelf: Quick Links" }) {
      ...NodeMenuFields
    }
  }
`;
