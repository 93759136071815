import React, { useEffect, useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import styled from 'styled-components'
import { colors } from '../../../vars/palette'

const FixedShelf = styled.div`
  /* transition: max-height 0.5s cubic-bezier(0, 0, 1, 1); */
  max-height: 73vh;
  background-color: ${colors.blue.hex};
  border-radius: 4px 4px 0 0;
  bottom: ${({ resourcesHeight }: {resourcesHeight: number}) => resourcesHeight ? `${resourcesHeight}px` : 0};
  display: block;
  position: fixed;
  width: 100vw;
  z-index: 999;

  button {
    border-radius: 4px;
    background-color: ${colors.blue.hex};
    color: ${colors.white.hex};
    box-sizing: border-box;
    width: 100%;
    border: none;
    padding: 20px 0;
  }

  svg {
    color: ${colors.white.hex};
    margin-left: 12px;
    height: 20px;
    width: 20px;
  }
`


const FixedResourceShelf = ({
  Shelf,
  Help
}: {
  Shelf: React.FC<{}>;
  Help: React.FC<{}>;
}) => {

  const [isOpen, setOpen] = useState(false);
  const [resourcesHeight, setResourcesHeight] = useState(0);

  useEffect(() => {
    const resources = document.querySelector(".resources-container");
    let watchSize: ResizeObserver;
    if (resources) {
      watchSize = new ResizeObserver(function (element) {
        const newResourceHeight =
          element[0] && element[0].contentRect && element[0].contentRect.height;

        if (newResourceHeight && newResourceHeight !== resourcesHeight) {
          setResourcesHeight(newResourceHeight - 2);
        }
      });
      watchSize.observe(resources);
    }

    return () => watchSize && watchSize.unobserve(resources);
  }, [resourcesHeight]);
  return (
    <FixedShelf resourcesHeight={resourcesHeight}>
      {Help && <Help />}
      <button onClick={() => setOpen(!isOpen)}>
        {"RESOURCES"}
        <span>{isOpen ? <FaAngleDown /> : <FaAngleUp />}</span>
      </button>
      {isOpen ? <Shelf /> : null}
    </FixedShelf>
  );
};

export default FixedResourceShelf;