import React, { useState, useEffect, ReactNode } from "react";
import { searchResult } from "../components/util/searchResultInterface";

export const SearchContext = React.createContext({
  searchResults: [],
  searchInput: "",
  setSearchResults: (results: unknown) => {},
  setSearchInput: (input: string) => {}
});

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const handleResults = (results: searchResult[]) => {
    setSearchResults(results);
  };
  const handleSearchInput = (input: string) => {
    setSearchInput(input);
  };
  // useEffect(() => {
  //   console.log("searchInput", searchInput)
  // }, [searchInput])
  // useEffect(() => {
  //   console.log("searchResults effect", searchResults);
  // }, [searchResults]);

  useEffect(() => {
    const sessionResults = JSON.parse(sessionStorage.getItem("searchResults"));
    handleResults(sessionResults);
  }, []);

  useEffect(() => {
    const sessionInput = JSON.parse(sessionStorage.getItem("searchInput"));
    handleSearchInput(sessionInput);
  }, []);

  // useEffect(() => {
  //   console.log("searchinput effect", searchInput);
  // }, [searchInput]);

  return (
    <SearchContext.Provider
      value={{
        searchResults: searchResults,
        searchInput: searchInput,
        setSearchResults: handleResults,
        setSearchInput: handleSearchInput
      }}>
      {children}
    </SearchContext.Provider>
  );
};
