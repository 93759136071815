import * as React from "react";
import { colors } from "../../../../vars/palette";

export interface IconProps {
  color?: string;
  title?: string;
}

export const ShelfIconDocument = ({ color, title }: IconProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    height="35.8"
    width="48"
    viewBox="0 0 48 35.8"
    fill={color ? color : colors.green.hex}
    aria-label={title}
    // style="enable-background:new 0 0 48 35.8;"
    xmlSpace="preserve">
    <style type="text/css"></style>
    <g>
      <path
        d="M4.9,13.5c0-2.8,2.3-5.2,5.2-5.2H43V4.9c0-1.2-1-2.2-2.2-2.2H17.1c0-1.5-1.2-2.7-2.7-2.7H2.2C1,0,0,1,0,2.2
		v29.7c0,2.1,1.7,3.9,3.9,3.9h1V13.5z"
      />
      <path
        d="M45.8,11.4H10c-1.2,0-2.2,1-2.2,2.2v22.2h37.9c1.2,0,2.2-1,2.2-2.2V13.5C48,12.3,47,11.4,45.8,11.4z"
      />
    </g>
  </svg>
);
