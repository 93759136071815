import * as React from "react";
import styled from "styled-components";
import get from "lodash/get";
import { WmkLink } from "wmk-link";
import { HiMenu, HiOutlineX } from "react-icons/hi";

/**
 * Wrapping styled-component element
 */
const Wrap = styled.div`
*{
  }
  button {
    border: none;
    background: none;
    color: #1f3e4f;
    padding: 0;
    margin: 0 0.75rem;
  }
  .navigation {
    margin: 0px;
    float: left;
    padding: 0px;
  }
  .navigation li {
    list-style: none;
    float: left;
  }
  .navigation li button {
    padding: 0.6rem 0.06px;
    text-align: center;
    display: block;
    text-decoration: none;
    position: relative;
    z-index: 12;
    line-height: 1.5rem;
    font-weight: 400;
    font-size: 0.9rem;
  }

  .show-menu-btn,
  .hide-menu-btn {
    transition: 0.4s;
    font-size: 1.8rem;
    cursor: pointer;
    display: none;
  }
  .show-menu-btn {
    margin: 0px;
    float: right;
  }

  #toggle-btn {
    position: absolute;
    visibility: hidden;
    z-index: -1111;
  }
  .rightItems {
    font-size: 1.5rem;
    float: right;
  }
  /*-- Responsive CSS --*/
  @media screen and (max-width: 768px) {
    .show-menu-btn,
    .hide-menu-btn {
      display: block;
    }
    .rightItems {
      display: none;
    }
    .navigation {
      position: fixed;
      width: 100%;
      height: 100vh;
      background: #001743;
      top: -100%;
      left: 0;
      padding: 1.25rem 0.6rem;
      transition: 1s;
      z-index: 1;
    }
    .navigation li {
      width: 100%;
    }
    .navigation li button, .navigation li a {
      padding: 0.3rem;
      text-align: left;
      color: #fff;      
    }

    .hide-menu-btn {
      position: absolute;
      top: 0.3rem;
      right: 1.25rem;
      color: white;
    }
    #toggle-btn:checked ~ nav .navigation {
      top: 0px;
    }
  }
  @media (min-device-width: 769px) and (max-device-width: 1024px) {
    button {
      margin: 0 0.1rem;
    }
  }
  @media print {
    display: none;
  }
`;

/**
 * @component - handles the logic/state and
 * view layer of multi-step mega menu
 * @param {Menu} menu
 * @param {Function} updateCurrMenu
 * @returns
 */
const MegaMenu = ({ menu, updateCurrMenu }) => {
  const topLevelLinks = get(menu, "links");
  return (
    <Wrap>
      <input type="checkbox" id="toggle-btn" />
      <label htmlFor="toggle-btn" className="show-menu-btn">
        <HiMenu />
      </label>
      <nav>
        <ul className="navigation">
          {topLevelLinks.map((link, i) => {
            const title = get(link, "title");
            const links = get(link, "links");
            const parent = get(link, "parent");
            const parentText = get(parent, "text");
            const text = get(link, "text");
            const to = get(link, "to");
            const target = get(link, "target");
            if (links) {
              return (
                <li key={i}>
                  <button onClick={() => updateCurrMenu(title)} key={title + i}>
                    {parentText}
                  </button>
                </li>
              );
            } else if (to) {
              return (
                <WmkLink to={to} target={target} key={title + i}>
                  {text}
                </WmkLink>
              );
            } else {
              return null;
            }
          })}
          <label htmlFor="toggle-btn" className="hide-menu-btn">
            <HiOutlineX />
          </label>
        </ul>
      </nav>
    </Wrap>
  );
};

export default MegaMenu;
