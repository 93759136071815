import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../../vars/palette";
import { Bg } from "../../../../calculators/kind/LCCA/Locked";
import CustomSearchIcon from "./SearchIcon";
import { SearchContext } from "../../../../../context/SearchContext";
import { Col, Row } from "react-bootstrap";
import { IoIosClose } from "react-icons/io";
import { searchResult } from "../../../../util/searchResultInterface";
import algoliasearch from "algoliasearch/lite";
import {
  AutocompleteState,
  BaseItem,
  createAutocomplete
} from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import "@algolia/autocomplete-theme-classic";

const DrawerWrap = styled.div`
  position: relative;
  z-index: 2000;
  .results {
    //padding: 0.5rem;
    border-radius: 0.25rem;
    background: white;
    width: 70%;
    margin: 0 15%;
    overflow-y: scroll;
    max-height: 65vh;
  }
  .wmk-search {
    & > .flex-column {
      margin-top: 10vh;
    }
  }
  .aa-Input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .aa-form {
    position: relative;
  }
  .aa-Autocomplete {
    width: 80%;
    position: relative;
  }
  .aa-Panel {
    width: 100%;
  }
  .aa-Source {
    padding: 0.375rem 0.75rem;
  }
`;

const StyledButton = styled.button<{ mobile?: true; open: true }>`
  background: none;
  border: none;
  padding: 10px 10px;
  font-size: 30px;
  position: relative;
  z-index: 200;
  ${({ mobile }) => (mobile ? `margin-bottom: 1.5rem;` : ``)}
  svg {
    path {
      fill: ${({ open, mobile }) =>
        mobile
          ? colors.white.hex
          : open
          ? colors.darkGray.hex
          : colors.black.hex};
    }
  }
`;

// interface autocompleteStateData {
//   activeItemId: string;
//   collections: [];
//   completion: null;
//   context: {};
//   isOpen: boolean;
//   query: string;
//   status: string;
// }

// interface itemdata {
//   dipra_source_index: {
//     exact_nb_hits: number;
//     facets: { exact_matches: {} };
//     analytics: {};
//   };

//   nb_words: number;
//   objectID: string;
//   popularity: number;
//   query: string;
//   __autocomplete_id: number;
//   __autocomplete_indexName: string;
//   __autocomplete_queryID: undefined;
//   _highlightResult: {
//     query: {
//       fullyHighlighted: false;
//       matchLevel: string;
//       matchedWords: string[];
//       value: string;
//     };
//   };
// }

const wmkClass = (name: string, group: string, append = "", prefix = "wmk") => {
  const classes = [
    prefix + "-" + group,
    prefix + "-" + group + "-" + name,
    append
  ];
  return classes.join(" ").trim();
};

export const SearchButton = ({
  isSearchOpen,
  setIsSearchOpen,
  mobile,
  closeMenu,
  isBurgerOpen
}: {
  isSearchOpen: boolean;
  setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobile?: boolean;
  closeMenu: Function;
  isBurgerOpen: boolean;
}) => {
  const [searchResults, setSearchResults] = useState<searchResult[]>([]);

  // useEffect(() => {
  //   sessionStorage.setItem("searchResults", JSON.stringify(searchResults));
  // }, [searchResults]);

  const toggle = () => setIsSearchOpen(!isSearchOpen);

  const _style = {
    position: isSearchOpen ? ("fixed" as const) : ("absolute" as const),
    zIndex: !isSearchOpen ? -1 : 20000,
    width: isSearchOpen ? "100%" : "1px",
    minHeight: isSearchOpen ? "100%" : "none",
    height: isSearchOpen ? "auto" : "1px",
    transition: "all 0.3s ease",
    right: 0,
    top: 0,
    overflow: isSearchOpen ? "scroll" : "hidden"
  };

  const closeButtonPosition = "in-bar";
  const closeButtonStyle = {
    position: "absolute",
    top: `5px`,
    right: 0,
    fontSize: `2rem`,
    appearance: "none",
    border: "none",
    background: "none"
  };

  const searchClient = algoliasearch(
    "ODQJXR6FON",
    "0e406faab78dc1d9c33a231cc3c27f2f"
  );

  const Autocomplete = ({
    searchResults,
    setResults,
    contextData,
    placeholder
  }: {
    searchResults: searchResult[];
    setResults: Function;
    contextData: {
      searchResults: searchResult[];
      searchInput: string;
      setSearchResults: (results: searchResult[]) => void;
      setSearchInput: (input: string) => void;
    };
    placeholder: string;
  }) => {
    const [autocompleteState, setAutocompleteState] = useState<
      AutocompleteState<BaseItem> | object
    >({});
    // const [searchResults, setSearchResults] = useState<IndexedSearch[]>([]);
    const [searchKey, setSearchKey] = useState("");

    useEffect(() => {
      sessionStorage.setItem("searchInput", JSON.stringify(searchKey));
    }, [searchKey]);
    const autocomplete = React.useMemo(
      () =>
        createAutocomplete({
          onStateChange({ state }) {
            // (2) Synchronize the Autocomplete state with the React state.
            setAutocompleteState(state);
            if (state.query !== searchKey) {
              setSearchKey(state.query);
            }
          },
          getSources() {
            return [
              // (3) Use an Algolia index source.
              {
                sourceId: "products",
                getItemInputValue({ item }) {
                  return item.query;
                },
                getItems({ query }) {
                  return getAlgoliaResults({
                    searchClient,
                    queries: [
                      {
                        indexName: "dipra_source_index_query_suggestions",
                        query,
                        params: {
                          hitsPerPage: 10,
                          highlightPreTag: "<mark>",
                          highlightPostTag: "</mark>"
                        }
                      }
                    ]
                  });
                }
              }
            ];
          }
        }),
      []
    );

    const handleClose = () => {
      setIsSearchOpen(false);
      setSearchKey("");
    };

    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      let aHits: searchResult[];
      setSearchKey(searchKey);

      if (window && window?.location?.origin) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/results`;
        }, 1000);
      }
      // const sIndex = searchClient.initIndex("dipra_source_index");
      // // Search for "query string" in the index "contacts"
      // sIndex
      //   .search(autocompleteState.query)
      //   .then(({ hits }) => {
      //     aHits = hits as searchResult[];
      //     console.log("hits", hits)
      //   })
      //   .then(() => {
      //     setResults(aHits);
      //     contextData.setSearchResults(aHits);
      //   }).then(() => {
      //     contextData.setSearchInput(searchKey)
      //   })
      //   .then(() => {
      //     if (
      //       window &&
      //       window?.location?.origin &&
      //       (window.location.pathname === "/results" ||
      //         window.location.pathname === "/results/")
      //     ) {
      //       if (isBurgerOpen) {
      //         closeMenu();
      //       }
      //       setIsSearchOpen(false);
      //     } else if (window && window?.location?.origin) {
      //       setTimeout(() => {
      //         window.location.href = `${window.location.origin}/results`;
      //       }, 1000);
      //     }
      //   });
    };

    return (
      <div className="aa-Autocomplete" {...autocomplete.getRootProps({})}>
        <form
          onSubmit={(e: React.FormEvent) => {
            handleSubmit(e);
          }}
          className="aa-form">
          <input
            className="aa-Input"
            {...autocomplete.getInputProps({})}
            placeholder={placeholder}
            autoFocus
          />
          {closeButtonPosition === "in-bar" ? (
            <button
              style={closeButtonStyle as React.CSSProperties}
              onClick={handleClose}
              aria-label="Close Search"
              className="close"
              type="button">
              <IoIosClose />
            </button>
          ) : null}
        </form>
        <div className="aa-Panel" {...autocomplete.getPanelProps({})}>
          {autocompleteState.isOpen &&
            autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection;

              return (
                <div key={`source-${index}`} className="aa-Source">
                  {items.length > 0 && (
                    <ul className="aa-List" {...autocomplete.getListProps()}>
                      {items.map((item) => {
                        return (
                          <li
                            key={item.objectID}
                            className="aa-Item"
                            {...autocomplete.getItemProps({
                              item,
                              source
                            })}>
                            {item.query}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const _className = [];
  _className.push(isSearchOpen ? "search-open" : "search-closed");

  return (
    <>
      <StyledButton
        name="search"
        //htmlFor="search"
        open={isSearchOpen ? true : undefined}
        onClick={toggle}
        mobile={mobile ? true : undefined}>
        <CustomSearchIcon />
      </StyledButton>

      {isSearchOpen ? (
        <DrawerWrap
          style={{
            position: `fixed`,
            zIndex: 100,
            height: `100vh`,
            width: `100vw`,
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center"
          }}>
          <Bg />
          <div
            style={_style}
            className={wmkClass("drawer", "search", _className.join(" "))}>
            <Row className="flex-column" style={{ width: "100%" }}>
              <Col
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "2vh 3vw",
                  minHeight: "5rem"
                }}></Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "5rem"
                }}>
                <SearchContext.Consumer>
                  {(searchData) => {
                    return (
                      <>
                        <Autocomplete
                          placeholder="Enter Search..."
                          openOnFocus={true}
                          className="search-form"
                          searchResults={searchResults}
                          setResults={setSearchResults}
                          contextData={searchData}
                        />
                      </>
                    );
                  }}
                </SearchContext.Consumer>
              </Col>
            </Row>
          </div>
        </DrawerWrap>
      ) : null}
    </>
  );
};
