import * as React from "react";
import { colors } from "../../../../vars/palette";
import { IconProps } from "./ShelfIconCalc";

/**
 */
export const ShelfIconMain = ({ color }: IconProps) => (
  <svg
    style={{ alignSelf: "center" }}
    id="ShelfIconMain_Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="54.025"
    height="58.937"
    viewBox="0 0 54.025 58.937">
    <path
      id="ShelfIconMain_Path_650"
      data-name="Path 650"
      d="M51.079,53.043a13.752,13.752,0,0,0-1.965-7.092V6.876a.982.982,0,0,0-.982-.982H43.22V.982A.982.982,0,0,0,42.238,0H.982A.982.982,0,0,0,0,.982V52.061a.982.982,0,0,0,.982.982H5.894v4.911a.982.982,0,0,0,.982.982h44.2a2.947,2.947,0,1,0,0-5.894ZM5.894,6.876v44.2H1.965V1.965H41.256V5.894H6.876A.982.982,0,0,0,5.894,6.876ZM20.8,56.972H7.858V7.858H47.149V43.407a13.752,13.752,0,0,0-5.894-3.546,2.947,2.947,0,0,0-2.947-2.534H36.344A2.947,2.947,0,0,0,33.4,39.861a13.693,13.693,0,0,0-9.823,13.182,2.947,2.947,0,0,0-2.947,2.947,3.025,3.025,0,0,0,.177.982Zm30.274,0h-27.5a.982.982,0,1,1,0-1.965.982.982,0,0,0,1.965,0V53.043a11.728,11.728,0,0,1,4.911-9.577v1.719a.982.982,0,0,0,1.965,0V42.326c.324-.147.648-.275.982-.393v6.2a.982.982,0,0,0,1.965,0V40.273a.982.982,0,0,1,.982-.982h1.965a.982.982,0,0,1,.982.982v7.858a.982.982,0,1,0,1.965,0v-6.2c.334.118.658.246.982.393v2.858a.982.982,0,1,0,1.965,0V43.466a11.728,11.728,0,0,1,4.911,9.577v1.965a.982.982,0,0,0,1.965,0,.982.982,0,0,1,0,1.965Z"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_651"
      data-name="Path 651"
      d="M47.646,53.947H40.77v-.982A1.965,1.965,0,0,0,38.805,51H36.841a1.965,1.965,0,0,0-1.965,1.965v.982H28.982a.982.982,0,1,0,0,1.965h6.876a.982.982,0,0,0,.982-.982V52.965h1.965v1.965a.982.982,0,0,0,.982.982h7.858a.982.982,0,1,0,0-1.965Z"
      transform="translate(-0.496 -0.904)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_652"
      data-name="Path 652"
      d="M14.982,21.823H40.522a.982.982,0,0,0,.982-.982V12.982A.982.982,0,0,0,40.522,12H14.982a.982.982,0,0,0-.982.982v7.858A.982.982,0,0,0,14.982,21.823Zm.982-7.858H39.539v5.894H15.965Z"
      transform="translate(-0.248 -0.213)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_653"
      data-name="Path 653"
      d="M16.947,26H14.982a.982.982,0,0,0,0,1.965h1.965a.982.982,0,0,0,0-1.965Z"
      transform="translate(-0.248 -0.461)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_654"
      data-name="Path 654"
      d="M40.628,26H20.982a.982.982,0,1,0,0,1.965H40.628a.982.982,0,0,0,0-1.965Z"
      transform="translate(-0.354 -0.461)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_655"
      data-name="Path 655"
      d="M16.947,30H14.982a.982.982,0,0,0,0,1.965h1.965a.982.982,0,0,0,0-1.965Z"
      transform="translate(-0.248 -0.532)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_656"
      data-name="Path 656"
      d="M40.628,30H20.982a.982.982,0,1,0,0,1.965H40.628a.982.982,0,1,0,0-1.965Z"
      transform="translate(-0.354 -0.532)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_657"
      data-name="Path 657"
      d="M16.947,34H14.982a.982.982,0,0,0,0,1.965h1.965a.982.982,0,0,0,0-1.965Z"
      transform="translate(-0.248 -0.602)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_658"
      data-name="Path 658"
      d="M40.628,34H20.982a.982.982,0,1,0,0,1.965H40.628a.982.982,0,0,0,0-1.965Z"
      transform="translate(-0.354 -0.602)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_659"
      data-name="Path 659"
      d="M16.947,38H14.982a.982.982,0,0,0,0,1.965h1.965a.982.982,0,1,0,0-1.965Z"
      transform="translate(-0.248 -0.673)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_660"
      data-name="Path 660"
      d="M30.805,38H20.982a.982.982,0,0,0,0,1.965h9.823a.982.982,0,0,0,0-1.965Z"
      transform="translate(-0.354 -0.673)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_661"
      data-name="Path 661"
      d="M16.947,42H14.982a.982.982,0,0,0,0,1.965h1.965a.982.982,0,0,0,0-1.965Z"
      transform="translate(-0.248 -0.744)"
      fill={color ? color : colors.white.hex}
    />
    <path
      id="ShelfIconMain_Path_662"
      data-name="Path 662"
      d="M25.894,43.965a.982.982,0,0,0,0-1.965H20.982a.982.982,0,1,0,0,1.965Z"
      transform="translate(-0.354 -0.744)"
      fill={color ? color : colors.white.hex}
    />
  </svg>
);
