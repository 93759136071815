import * as React from 'react';
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { colors } from '../../../vars/palette'; 

const Wrap = styled.div`
  * {
  }
  color: ${colors.white.hex};
  font-size: .7rem;
  padding-top: 1rem;
  .container {
    padding: 0 !important;
  }
  @media only screen and (max-width: 1199px) {
    .container {
      display: flex;
      justify-content: center;
      padding-top: 1rem !important;
    }
  }
`
 
const Copyright = props => {
  return (
    <Wrap>
      <Container>
        <p> © Copyright {new Date().getFullYear()} Ductile Iron Pipe Research Association</p>
      </Container>      
    </Wrap>
  )
}
 
export default Copyright