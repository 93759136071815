import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Header } from "./Header";
import styled from "styled-components";

const StickyWrap = styled.div`
  ${({ isTriggered, domPosition }) =>
    isTriggered ? "position: fixed; animation: repaint 1ms;" : domPosition}
  ${({ zIndex }) => (zIndex ? `z-index: ${zIndex};` : null)}
  ${({ width }) => (width ? `width: ${width};` : null)}
  @keyframes repaint {
    from {
      width: 99.999%;
    }
    to {
      width: 100%;
    }
  }
`;

export const Sticky = ({
  Alert,
  className,
  children,
  absolute,
  style,
  zIndex,
  width,
  trigger
}) => {
  const domPosition = absolute ? "absolute" : "relative";
  const [headerRect, setHeaderRect] = useState({ y: 0, height: 0, width: 0 });
  const [scrollPos, setScrollPos] = useState(0);

  const headerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setHeaderRect(headerRef.current.getBoundingClientRect());
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useScrollPosition(({ currPos }) => {
    setScrollPos(currPos.y * -1);
  });
  const classes = [className];
  const triggerHeight =
    !Alert && !headerRect.y
      ? trigger >= 0
        ? trigger
        : headerRect.height
      : headerRect.y;
  const isTriggered = scrollPos > triggerHeight;
  if (isTriggered) classes.push("stuck");
  return (
    <React.Fragment>

      <StickyWrap
        className={classes.join(" ")}
        isTriggered={isTriggered}
        domPosition={domPosition}
        zIndex={zIndex}
        width={width}
        style={{ ...style }}>
        {!isTriggered ? Alert : null}
        <Header ref={headerRef}>{children}</Header>
      </StickyWrap>
      {Alert && isTriggered ? <div style={{ height: headerRect.y }} /> : null}
      {isTriggered && <div style={{ height: headerRect.height }} />}
    </React.Fragment>
  );
};

Sticky.propTypes = {
  Alert: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  absolute: PropTypes.bool,
  zIndex: PropTypes.number,
  width: PropTypes.string,
  trigger: PropTypes.number
};

Sticky.defaultProps = {
  Alert: <React.Fragment />,
  className: "",
  absolute: false,
  zIndex: 1000,
  width: "100%",
  trigger: 0
};
