import * as React from "react";
import { useState, useEffect } from "react";
import get from "lodash/get";
import MegaDrawer from "./Drawer/MegaDrawer";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../../vars/palette";
import TopLinksDrawer from "./Drawer/TopLinksDrawer";
import { HiArrowLeft, HiX } from "react-icons/hi";
import { MAINBAR_MOBILE_BREAK } from "../../Header";
import SitemapCta from "./MegaCta/SitemapCta";
import MegaCta from "./MegaCta/MegaCta";
import { SearchButton } from "../Search/Search";
import { Menu } from "../../../../../classes/Menu";
import { Img, WmkImage } from "wmk-image";
import { WmkLink } from "wmk-link";
import { HyperLink } from "../../../../../classes/HyperLink";

const Cabinet = styled(Container)`
  position: absolute;
  max-height: ${({ width }) =>
    width > MAINBAR_MOBILE_BREAK ? `70vh` : `none`};
  height: ${({ width }) =>
    width > MAINBAR_MOBILE_BREAK ? `auto` : `calc(100vh - 86.5px)`};
  z-index: 500;
  background: ${colors.darkBlue.hex};
  color: ${colors.white.hex};
  overflow: scroll;
  padding-top: 1rem;
  .drawerRow {
    position: relative;
    display: flex;
    ${({ menuDepth }) =>
    menuDepth > 1 ? `justify-content: flex-start;` : `justify-content: space-between;`}
  }
  .megaMenuCta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-right: 4vw;
    margin-top: calc(38px);
  }
  .megaMenuImage {
    // position: absolute;
    // width: 50%;
    // right: 4vw;
    // top: calc(43% - 8vw);
    // max-height: 320px;
  }
`;

const CloseButton = styled.button`
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 3vw;
  background: none;
  border: none;
  color: ${colors.white.hex};
  z-index: 501;
`;

const BackButton = styled.button`
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  left: 3vw;
  background: none;
  border: none;
  color: ${colors.white.hex};
  z-index: 501;
`;

const MegaCabinet = ({
  viewWidth,
  megaMenu,
  topbarMenu,
  toggleBurger,
  isBurgerOpen,
  currMenu,
  updateCurrMenu,
  searchToggle,
  searchHandler
}: {
  viewWidth: number;
  megaMenu: Menu;
  topbarMenu: Menu;
  toggleBurger: Function;
  isBurgerOpen: boolean;
  currMenu: Menu;
  updateCurrMenu: Function;
  searchToggle: boolean;
  searchHandler: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [activeMenu, setActiveMenu] = useState();
  const [drawerStack, setDrawerStack] = useState([]);
  const [menuDepth, setMenuDepth] = useState(0);

  const subLinks = get(megaMenu, `links`, []);

  const currentMegaMenuLink =
    megaMenu?.megaMenuLinks[
      Math.floor(Math.random() * megaMenu?.megaMenuLinks?.length)
    ];

  const currentMegaLinkImage = new Img(currentMegaMenuLink?.image);
  const currentMegaLinkLink = new HyperLink(currentMegaMenuLink?.link);

  // const testImage = new Img(megaMenu?.megaMenuLinks[0]?.image);
  // const testLink = new HyperLink(megaMenu.megaMenuLinks[0].link);

  const handleGrowStack = (menu) => {
    setDrawerStack((prev) => {
      prev.push(menu);
      setMenuDepth(prev.length);
      return prev;
    });
  };

  const handleShrinkStack = (menu, level) => {
    const curTitle = get(drawerStack, `[${level}].title`);
    const thisTitle = get(menu, `title`);
    // if parent matches current next tier, retreat menu
    if (curTitle === thisTitle) {
      setDrawerStack((prev) => {
        prev.pop();
        setMenuDepth(prev.length);
        return prev;
      });
    } else {
      setDrawerStack((prev) => {
        const newStack = prev.slice(0, level);
        newStack.push(menu);
        setMenuDepth(newStack.length);
        return newStack;
      });
    }
  };

  const handleClose = () => {
    setDrawerStack([]);
    updateCurrMenu(null);
    if (isBurgerOpen) {
      toggleBurger();
    }
  };

  const handleGoBack = () => {
    setDrawerStack((prev) => {
      prev.pop();
      setMenuDepth(prev.length);
      return prev;
    });
  };

  useEffect(() => {
    if (activeMenu) {
      setDrawerStack((prev) => {
        if (prev !== activeMenu) {
          setMenuDepth(1);
          return [activeMenu];
        } else {
          return prev;
        }
      });
    }
  }, [activeMenu]);

  useEffect(() => {
    setActiveMenu(() => {
      return subLinks.reduce((match, each) => {
        const subTitle = get(each, `title`);
        if (subTitle === currMenu) {
          match = each;
        }
        return match;
      }, null);
    });
  }, [currMenu, subLinks]);

  useEffect(() => {
    if (isBurgerOpen) {
      setDrawerStack([megaMenu]);
      setActiveMenu(megaMenu);
      updateCurrMenu(megaMenu);
    } else {
      setDrawerStack([]);
      setActiveMenu(null);
      updateCurrMenu(null);
    }
  }, [isBurgerOpen, megaMenu]);

  return currMenu ? (
    <Cabinet fluid width={viewWidth} menuDepth={menuDepth}>
      <CloseButton aria-label="Close Menu" onClick={handleClose}>
        <HiX />
      </CloseButton>
      {isBurgerOpen && menuDepth > 1 ? (
        <BackButton aria-label="Go Back" onClick={handleGoBack}>
          <HiArrowLeft />
        </BackButton>
      ) : null}
      <Row className="drawerRow">
        {drawerStack.map((drawer, i) => {
          return (
            <MegaDrawer
              viewWidth={viewWidth}
              activeMenu={drawer}
              key={get(drawer, `title`)}
              depth={menuDepth}
              grow={handleGrowStack}
              shrink={handleShrinkStack}
              level={i + 1}
              searchToggle={searchToggle}
              searchHandler={searchHandler}
            />
          );
        })}
        {viewWidth >= 1200 && drawerStack?.length <= 2 && (
          <div className="megaMenuCta">
            <WmkLink
              to={currentMegaLinkLink.to}
              target={currentMegaLinkLink.target}>
              <WmkImage
                image={currentMegaLinkImage}
                className="megaMenuImage"
              />
            </WmkLink>
          </div>
        )}
      </Row>
      <TopLinksDrawer
        isBurgerOpen={isBurgerOpen}
        viewWidth={viewWidth}
        topbarMenu={topbarMenu}
      />
      <SearchButton
        isSearchOpen={searchToggle}
        setIsSearchOpen={searchHandler}
        mobile={true}
        closeMenu={toggleBurger}
        isBurgerOpen={isBurgerOpen}
      />
      <MegaCta depth={menuDepth} viewWidth={viewWidth}>
        <SitemapCta />
      </MegaCta>
    </Cabinet>
  ) : null;
};

export default MegaCabinet;
