import * as React from "react";
import { useState, useRef, useEffect } from "react";
import {
  //Container,
  //Row,
  Col
} from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import { HyperLink } from "../../../../../../classes/HyperLink";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../../../vars/palette";
import { Menu, MenuItem } from "../../../../../../classes/Menu";
import { MAINBAR_BURGER_BREAK } from "../../../Header";
import { HiOutlineChevronRight } from "react-icons/hi";
import { WmkImage } from "wmk-image";

const Drawer = styled(Col)`
  padding: 6vh 2vw;
  position: relative;
  @media screen and (min-width: 1200px) {
    flex: 0 0 25% !important;
  }
`;

const ParentWrap = styled(Col)`
  flex-grow: 0 !important;
  a {
    ${({ level, burger }) =>
      level > 1 && !burger ? `text-transform: uppercase;` : ``}
    transition: all 0.3s ease;
    color: ${colors.green.hex};
    font-weight: ${({ level, burger }) => (!burger ? 700 : 500)};
    font-size: ${({ level, burger }) =>
      level > 1 && !burger ? `.875rem` : `1rem`};
    text-decoration: none;
    &:hover {
      transition: all 0.3s ease;
      text-decoration: underline;
      color: ${colors.techNavGreen.hex};
    }
  }
  ${({ level, burger }) => (level > 1 && !burger ? `margin-top: 2.25rem;` : ``)}
  margin-bottom: 2vh;
`;

const MenuParent = ({ parentLink, level, isBurger }) => {
  const _text = get(parentLink, `text`, "");
  const text = level > 1 && !isBurger ? `${_text} Overview` : _text;
  return text ? (
    <ParentWrap level={level} burger={isBurger ? "true" : undefined}>
      <WmkLink to={get(parentLink, `to`)} target={get(parentLink, `target`)}>
        {text}
      </WmkLink>
    </ParentWrap>
  ) : null;
};

const ChildWrap = styled(Col)`
  flex-grow: 0 !important;
  button,
  a {
    text-transform: uppercase;
    font-size: 0.9rem;
    display: block;
    border: none;
    background: none;
    color: ${colors.darkGray.hex};
    padding: 0.37rem 0.12rem;
    text-decoration: none;
    text-align: left;
    &:hover {
      color: ${colors.white.hex};
      text-decoration: underline !important;
    }
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  }
`;

const ParentButton = styled.button`
  svg {
    margin-left: 0.5rem;
    color: ${colors.green.hex};
  }
`;

const Divider = styled.div`
  width: 1px;
  height: ${({ height }) => height * 0.975}px;
  border-right 1px solid ${colors.darkerGray.hex};
  position: absolute;
  top: 2.5rem;
  left: -1.5rem;
`;

const SubMenuItem = ({
  link,
  children,
  depth,
  grow,
  shrink,
  level,
  viewWidth
}) => {
  const handleSubClick = () => {
    // level is same as depth, so attempt to open next level
    if (level === depth) {
      grow(link);
    }
    // level is less than depth, so menu should either retreat
    // or open another sub menu
    if (level < depth) {
      shrink(link, level);
    }
  };

  const isMega = viewWidth > MAINBAR_BURGER_BREAK;

  return (
    <ChildWrap>
      {link instanceof Menu ? (
        // <div style={{ display: "flex", alignItems: "center" }}>
        <ParentButton onClick={handleSubClick}>
          {children}
          {isMega ? <HiOutlineChevronRight /> : null}
        </ParentButton>
      ) : (
        // </div>
        <WmkLink to={get(link, `to`)} target={get(link, `target`)}>
          {children}
        </WmkLink>
      )}
    </ChildWrap>
  );
};

const getSubMenuItemTitle = (link) => {
  let title;
  switch (true) {
    case link instanceof Menu:
      title = get(link, `parent.text`);
      break;
    case link instanceof MenuItem || link instanceof HyperLink:
      title = get(link, `text`);
      break;
    default:
      title = "Title Error";
  }
  return title;
};

const MegaDrawer = ({ activeMenu, depth, grow, shrink, level, viewWidth, currentMegaLinkImage, currentMegaLinkLink }) => {
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });

  const parentLink = new HyperLink(get(activeMenu, `parent`));
  const subLinks = get(activeMenu, `links`, []);
  const contentRef = useRef();

  const isBurger = viewWidth < MAINBAR_BURGER_BREAK;
  const sameLevel = depth === level;

  const visibility = isBurger ? activeMenu && sameLevel : activeMenu;

  useEffect(() => {
    const current = contentRef.current;
    if (current) {
      const box = current.getBoundingClientRect();
      setContentSize(box);
    }
  }, [contentRef, setContentSize]);

  return visibility ? (
    <Drawer>
      <div ref={contentRef} style={{ position: "relative" }}>
        <MenuParent parentLink={parentLink} level={level} isBurger={isBurger} />
        {subLinks.map((link, i) => {
          const subTitle = getSubMenuItemTitle(link);
          return (
            <SubMenuItem
              link={link}
              key={subTitle + i}
              level={level}
              depth={depth}
              grow={grow}
              shrink={shrink}
              viewWidth={viewWidth}>
              {subTitle}
            </SubMenuItem>
          );
        })}
        {!isBurger && level > 1 ? (
          <Divider height={get(contentSize, `height`)} />
        ) : null}
      </div>
    </Drawer>
  ) : null;
};

export default MegaDrawer;
