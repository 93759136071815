import React, { useRef, createContext, useContext, useCallback } from "react";

const IubendaCtx = createContext(null);

export const IubendaCtxProvider = ({ children }) => {
  const useNoticeAtCollectionRef = useRef();
  const usePivacyOptionsRef = useRef();

  return (
    <IubendaCtx.Provider
      value={{
        useNoticeAtCollectionRef: useNoticeAtCollectionRef,
        usePivacyOptionsRef: usePivacyOptionsRef
      }}>
      {children}
    </IubendaCtx.Provider>
  );
};

export const useNoticeAtCollection = () => {
  const ref = useContext(IubendaCtx).useNoticeAtCollectionRef;
  // console.log("useNoticeAtCollection ref", ref);

  return useCallback(() => ref.current?.click(), [ref]);
};

export const usePivacyOptions = () => {
  const ref = useContext(IubendaCtx).usePivacyOptionsRef;
  // console.log("usePivacyOptions ref", ref);

  return useCallback(() => ref.current?.click(), [ref]);
};

export const NoticeAtCollectionHiddenLink = () => {
  const ref = useContext(IubendaCtx).useNoticeAtCollectionRef;

  return (
    <a ref={ref} className="iubenda-cs-uspr-link" style={{ display: "none" }} />
  );
};

export const PrivacyOptionsHiddenLink = () => {
  const ref = useContext(IubendaCtx).usePivacyOptionsRef;
  return (
    <a
      ref={ref}
      className="iubenda-cs-preferences-link"
      style={{ display: "none" }}
    />
  );
};
