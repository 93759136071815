import { LinkTarget } from "wmk-link";
import { slugifyTitle } from "wmk-tag";
import { BlockIconCardButton } from "../fragments/NodeRichTextFields";

export const getButtonLink = (
  button: BlockIconCardButton | null,
  buttonLabel: string
) => {
  return button && button !== null
    ? new ButtonLink(button, buttonLabel)
    : undefined;
};

/**
 * @class
 * Object that represents exernal hyperlinks
 */
export class ButtonLink {
  /**
   */
  text: string;
  to: string;
  target?: LinkTarget;
  name?: string;
  sysId?: string;
  rootPath: string;
  constructor(node: BlockIconCardButton, buttonLabel = "") {
    const _target = node?.target ? node.target : undefined;
    const _text = buttonLabel !== "" ? buttonLabel : node.text;
    const slug = node?.slug ? "/" + node.slug : "";
    const path =
      node.parentPath && node.parentPath !== "/" ? node.parentPath : "";
    const root =
      node.__typename === "ContentfulDocument" || node.document?.slug
        ? "/docs"
        : node.__typename === "ContentfulResource"
        ? "/technical-resources"
        : node.__typename === "ContentfulNews"
        ? "/news/news-and-commentary"
        : node.__typename === "ContentfulMemberSectionPage"
        ? "/member-section"
        : "";
    const to = node.document?.slug
      ? `/docs/${node.document.slug}`
      : node.mediaLink?.title
      ? `/docs/${slugifyTitle(node.mediaLink.title)}`
      : node.path
      ? node.path
      : `${root}${path}${slug}`;
    const text = _text ? _text : node.title;
    const isExternal = typeof to === "string" && to.indexOf("http") === 0;
    const target =
      _target === "New Window"
        ? "blank"
        : _target === "Same Window"
        ? "self"
        : isExternal
        ? "blank"
        : undefined;
    const sysId = node.sys ? node.sys.contentType.sys.id : undefined;
    this.text = text || undefined;
    this.to = to;
    this.target = target;
    this.name = node.title;
    this.sysId = sysId;
    this.rootPath = root;
  }
}
