import * as React from "react";
import { colors } from "../../../../vars/palette";
import { IconProps } from "./ShelfIconCalc";

export const ShelfIconPhone = ({ color }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.398"
    height="33.396"
    viewBox="0 0 33.398 33.396">
    <path
      id="Icon_ionic-ios-call"
      data-name="Icon ionic-ios-call"
      d="M36.924,30.652a28.053,28.053,0,0,0-5.86-3.921c-1.756-.843-2.4-.826-3.643.07-1.035.748-1.7,1.443-2.9,1.182s-3.539-2.035-5.817-4.3-4.052-4.625-4.3-5.817.443-1.861,1.182-2.9c.9-1.243.922-1.887.07-3.643a27.5,27.5,0,0,0-3.921-5.86c-1.278-1.278-1.565-1-2.269-.748a12.913,12.913,0,0,0-2.078,1.1,6.269,6.269,0,0,0-2.5,2.634c-.5,1.069-1.069,3.06,1.852,8.26a46.089,46.089,0,0,0,8.1,10.807h0l.009.009.009.009h0a46.268,46.268,0,0,0,10.807,8.1c5.2,2.921,7.19,2.348,8.26,1.852A6.162,6.162,0,0,0,36.568,35a12.913,12.913,0,0,0,1.1-2.078C37.924,32.217,38.211,31.93,36.924,30.652Z"
      transform="translate(-4.49 -4.502)"
      fill={color ? color : colors.green.hex}
    />
  </svg>
);
