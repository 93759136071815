import * as React from "react";
import { colors } from "../../../../vars/palette";

export interface IconProps {
  color?: string;
  title?: string;
}

export const ShelfIconCalc = ({ color, title }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37.418"
    height="42.764"
    viewBox="0 0 37.418 42.764"
    aria-label={title}>
    <path
      id="Icon_awesome-calculator"
      data-name="Icon awesome-calculator"
      d="M33.409,0H4.009A4.111,4.111,0,0,0,0,4.009V38.755a4.111,4.111,0,0,0,4.009,4.009h29.4a4.111,4.111,0,0,0,4.009-4.009V4.009A4.111,4.111,0,0,0,33.409,0ZM10.691,36.349a1.15,1.15,0,0,1-1.069,1.069H6.415a1.15,1.15,0,0,1-1.069-1.069V33.142a1.15,1.15,0,0,1,1.069-1.069H9.622a1.15,1.15,0,0,1,1.069,1.069Zm0-10.691a1.15,1.15,0,0,1-1.069,1.069H6.415a1.15,1.15,0,0,1-1.069-1.069V22.451a1.15,1.15,0,0,1,1.069-1.069H9.622a1.15,1.15,0,0,1,1.069,1.069ZM21.382,36.349a1.15,1.15,0,0,1-1.069,1.069H17.105a1.15,1.15,0,0,1-1.069-1.069V33.142a1.15,1.15,0,0,1,1.069-1.069h3.207a1.15,1.15,0,0,1,1.069,1.069Zm0-10.691a1.15,1.15,0,0,1-1.069,1.069H17.105a1.15,1.15,0,0,1-1.069-1.069V22.451a1.15,1.15,0,0,1,1.069-1.069h3.207a1.15,1.15,0,0,1,1.069,1.069ZM32.073,36.349A1.15,1.15,0,0,1,31,37.418H27.8a1.15,1.15,0,0,1-1.069-1.069v-13.9A1.15,1.15,0,0,1,27.8,21.382H31a1.15,1.15,0,0,1,1.069,1.069Zm0-21.382A1.15,1.15,0,0,1,31,16.036H6.415a1.15,1.15,0,0,1-1.069-1.069V6.415A1.15,1.15,0,0,1,6.415,5.345H31a1.15,1.15,0,0,1,1.069,1.069Z"
      fill={color ? color : colors.green.hex}
    />
  </svg>
);
