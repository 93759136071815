import * as React from "react";
import styled from "styled-components";
import { MAINBAR_MOBILE_BREAK } from "../../../Header";

const CtaWrap = styled.div`
  position: absolute;
  bottom: 4vh;
  right: 4vw;
`;

const MegaCta = ({ children, depth, viewWidth }) => {
  return depth < 4 && viewWidth > MAINBAR_MOBILE_BREAK ? (
    <CtaWrap>{children}</CtaWrap>
  ) : null;
};

export default MegaCta;
