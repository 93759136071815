import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { WmkLink } from "wmk-link";

const StaticReverseLogo = () => {
  return (
    <WmkLink to="/">
      <StaticImage
        src="../../../images/logos/DIPRA-logo-Reverse.png"
        alt="Ductile Iron Pipe Research Association"
        width={260}
        height={140}
        placeholder={"none"}
        layout={"constrained"}
        transformOptions={{ fit: "contain" }}
        backgroundColor={"transparent"}
        quality={95}
      />
    </WmkLink>
  );
};

export default StaticReverseLogo;
