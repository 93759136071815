import * as React from "react";
import { colors } from "../../../../vars/palette";
import { IconProps } from "./ShelfIconCalc";

export const ShelfIconPdf = ({ color }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.048"
    height="83.262"
    viewBox="0 0 64.048 83.262">
    <g transform="translate(-187 -218)">
      <g transform="translate(187 218)">
        <path
          d="M45.179,3.375H14.756A8.006,8.006,0,0,0,6.75,11.381v67.25a8.006,8.006,0,0,0,8.006,8.006H62.792A8.006,8.006,0,0,0,70.8,78.631V28.994ZM41.976,32.2V9.78L64.393,32.2Z"
          transform="translate(-6.75 -3.375)"
          fill={color ? color : colors.darkBlue.hex}
        />
      </g>
      <path
        d="M10.89,3.3H4.55V18.712H7.412V14.264H10.89c4.051,0,6.561-2.092,6.561-5.482C17.451,5.37,14.941,3.3,10.89,3.3Zm-.132,8.542H7.412V5.722h3.347c2.51,0,3.809,1.123,3.809,3.06S13.268,11.842,10.758,11.842Zm9.555,6.869H27.05c5,0,8.41-3.082,8.41-7.706S32.048,3.3,27.05,3.3H20.313Zm2.862-2.422V5.722h3.743c3.435,0,5.658,2.092,5.658,5.284s-2.224,5.284-5.658,5.284ZM49.749,5.7V3.3H38.5V18.712h2.862v-5.9H48.8V10.389H41.361V5.7Z"
        transform="translate(192.459 260.734)"
        fill="#fff"
      />
    </g>
  </svg>
);
