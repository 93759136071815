import { Form } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../vars/palette";

export const formStyles = `
  position: relative;
  margin: 3rem 0;
  .form-col {
    margin-bottom: 1.5rem;
  }
  .form-group {
    position: relative;
  }
  input,
  textarea,
  select {
    border-radius: 0;
    border: 3px solid ${colors.darkBlue.hex};
    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  label {
    position: absolute;
    left: 3rem;
    top: -0.75rem;
    background: ${colors.white.hex};
    color: ${colors.darkBlue.hex};
    padding: 0 0.5rem;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
  .hidden {
    opacity: 0;
    height: 0;
    position: absolute;
    top: -100vh;
  }
  .submit-reset {
    button {
      width: 100%;
      margin-right: 1rem;
      padding: 0.8rem;
      background: ${colors.darkBlue.hex};
      border: none;
      border-radius: 0;
      :hover {
        background: ${colors.blue.hex};
      }
    }
  }
`
export const StyledForm = styled(Form)`
  ${formStyles}
`;

export const altFormStyles = `
  position: relative;
  margin: 3rem 0;
  .form-col {
    margin-bottom: 1.5rem;
    padding-left: 6px;
    padding-right: 6px;
  }
  .form-group {
    position: relative;
  }
  input,
  textarea,
  select {
    border-radius: 0;
    // border: 1px solid ${colors.darkBlue.hex};
    border: 1px solid rgb(200,201,201);
    color: rgb(148, 148, 148);
    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
  label {
    position: relative;
    top: 8px;
    background: ${colors.white.hex};
    color: rgb(148, 148, 148);
    text-transform: uppercase;
    font-weight: 500;
  }
  .hidden {
    opacity: 0;
    height: 0;
    position: absolute;
    top: -100vh;
  }
  .submit-reset {
    button {
      width: 100%;
      margin-right: .5rem;
      padding: 0.8rem;
      background: ${colors.darkBlue.hex};
      border: none;
      border-radius: 0;
      :hover {
        background: ${colors.blue.hex};
      }
    }
  }
`

export const AltStyledForm = styled(Form)`
  ${altFormStyles}
`;
