import * as React from "react";
import { colors } from "../../../../vars/palette";
import { IconProps } from "./ShelfIconCalc";

export const ShelfIconEmail = ({ color }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33.709"
    height="26.967"
    viewBox="0 0 33.709 26.967">
    <path
      id="Icon_material-email"
      data-name="Icon material-email"
      d="M33.338,6H6.371A3.366,3.366,0,0,0,3.017,9.371L3,29.6a3.381,3.381,0,0,0,3.371,3.371H33.338A3.381,3.381,0,0,0,36.709,29.6V9.371A3.381,3.381,0,0,0,33.338,6Zm0,6.742L19.855,21.169,6.371,12.742V9.371L19.855,17.8,33.338,9.371Z"
      transform="translate(-3 -6)"
      fill={color ? color : colors.green.hex}
    />
  </svg>
);
