import * as React from 'react';
import styled from 'styled-components'
import { colors } from '../../../../vars/palette';
 
const Wrap = styled.div`
  * {
  }
  h3 {
    font-size: 1.5rem;
    color: ${colors.white.hex};
    b {
      font-weight: 900;
    }
    .blue {
      color: ${colors.blue.hex};
    }
    .green {
      color: ${colors.green.hex};
    }
  }
  @media only screen and (max-width: 991px) {
    margin: 2rem 0;
    h3 {
      font-size: 3vw;
    }
  }
  @media only screen and (max-width: 600px) {
    h3 {
      font-size: 4vw;
    }
  }
`
 
const StrengthAndDurability = props => {
  return (
    <Wrap>
      <h3><span><b>Strength</b></span> and <span className="blue"><b>Durability</b></span> for <span className="green"><b>LiFe</b><sup>®</sup></span></h3>
    </Wrap>
  )
}
 
export default StrengthAndDurability