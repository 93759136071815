import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../vars/palette";
import { AltStyledForm } from "../../../ui/Forms";
import { BREAKPOINT_MEDIUM_MAX, Type } from "../../../ui/Typography";
import { CalculatorButton } from "../../LoadAndSave";

const pw = `00490072006f006e0034004c006900460065`;

const hexEncode = (str) => {
  var hex, i;

  var result = "";
  for (i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ("000" + hex).slice(-4);
  }

  return result;
};

// const hexDecode = (str) => {
//   var j;
//   var hexes = str.match(/.{1,4}/g) || [];
//   var back = "";
//   for (j = 0; j < hexes.length; j++) {
//     back += String.fromCharCode(parseInt(hexes[j], 16));
//   }

//   return back;
// };

export const Bg = styled.div`
  filter: blur(50px);
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
`;

const LockBox = styled.div`
  padding: 8vh 12vw;
  background: ${colors.white.hex};
  position: relative;
  z-index: 100002;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  max-width: 80vw;
  @media screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    padding: 6vh 6vw;
    overflow: scroll;
  }
`;

const ExitButton = styled.div`
  border: none;
  background: none;
  font-size: 1.25rem;
  position: absolute;
  top: 3vh;
  right: 10vh;
  @media screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    top: 1vh;
    right: 2vh;
  }
  a {
    color: ${colors.black.hex};
    &:hover {
      color: ${colors.blue.hex};
    }
  }
`;

const Locked = ({ children }) => {
  const [isLocked, setIsLocked] = useState(true);
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);
  const memberCompanies = useStaticQuery(query).mc.edges.map((e) => e.node);

  useEffect(() => {
    if (window && sessionStorage?.getItem("memberSectionUnlocked")) {
      setIsLocked(false)
    }
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    const hexed = hexEncode(pass);
    if (hexed === pw) {
      setError(false);
      setIsLocked(false);
      sessionStorage.setItem("memberSectionUnlocked", "true")
    } else {
      setError(true);
      setPass("");
    }
  };

  const handleChange = (e) => {
    const p = e.target.value;
    setPass(() => {
      if (p === "") {
        setError(false);
      }
      return p;
    });
  };

  return isLocked ? (
    <div
      style={{
        position: `fixed`,
        zIndex: 100001,
        height: `100vh`,
        width: `100vw`,
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
      <Bg />

      <LockBox>
        <ExitButton>
          <WmkLink to="/">
            <FaTimes />
          </WmkLink>
        </ExitButton>
        <Type.P style={{ textAlign: "center" }}>
          We provide certain resources for the convenience of DIPRA’s member
          companies, including a Life Cycle Cost Analysis Calculator.
        </Type.P>
        <Type.P style={{ textAlign: "center" }}>
          If you have any questions about the Life Cycle Cost Analysis
          Calculator, please contact your DIPRA Regional Engineers.
        </Type.P>
        <Type.P style={{ textAlign: "center" }}>
          Enter the password below to gain access to the Member Section
        </Type.P>
        <Row
          style={{
            width: "100%",
            justifyContent: "center",
            padding: "1.5rem 0"
          }}>
          {memberCompanies.map((logo, i) => {
            return (
              <Col
                xs="auto"
                style={{ padding: "0 .25rem" }}
                key={logo.title + i}>
                <GatsbyImage
                  image={logo.image.gatsbyImageData}
                  alt={logo.title}
                />
              </Col>
            );
          })}
        </Row>
        <div style={{ maxWidth: "60vw", margin: "0 auto", width: `100%` }}>
          <AltStyledForm onSubmit={handleSubmit} style={{ margin: 0 }}>
            <AltStyledForm.Label>
              Enter Member Login Password
            </AltStyledForm.Label>
            <AltStyledForm.Control
              type="password"
              placeholder="Password"
              value={pass}
              onChange={handleChange}
              autoComplete={"current-password"}
            />
            <CalculatorButton type="submit">Submit</CalculatorButton>
          </AltStyledForm>
          {error ? (
            <Card
              bg={"warning"}
              style={{
                display: `flex`,
                justifyContent: "center",
                alignItems: "center"
              }}>
              <Type.P style={{ padding: `1rem 1rem 0 1rem` }}>
                Invalid password. Please try again.
              </Type.P>
            </Card>
          ) : null}
        </div>
      </LockBox>
    </div>
  ) : (
    <>{children}</>
  );
};

export default Locked;

const query = graphql`
  {
    mc: allContentfulMemberCompany(sort: { fields: title, order: DESC }) {
      edges {
        node {
          title
          image: logo {
            gatsbyImageData(width: 75, height: 75, resizingBehavior: PAD)
          }
        }
      }
    }
  }
`;
