import * as React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../vars/palette";
import { AltStyledForm } from "../ui/Forms";
import { useState, useEffect } from "react";
import get from "lodash/get";
import { Type } from "../ui/Typography";

export const NotPrintable = styled.div`
  @media print {
    display: none;
  }
`;

export const CalculatorButton = styled.button`
  width: 100%;
  display: inline-block;
  margin: ${({ type }) => (type === "submit" ? `.5rem 0` : `1rem 0`)};
  border: none;
  background: ${colors.darkBlue.hex};
  color: ${colors.white.hex};
  text-transform: uppercase;
  &:disabled {
    background: ${colors.darkerGray.hex};
  }
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  transition: all 0.3s ease;
  &:hover {
    background: ${colors.blue.hex};
    &:disabled {
      background: ${colors.darkerGray.hex};
    }
  }
`;

const jsonifyData = (data) => {
  if (Array.isArray(data) && data.length) {
    return data.reduce((json, stream) => {
      const key = Object.keys(stream)[0];
      json[key] = stream[key];
      return json;
    }, {});
  } else {
    return { error: "incorrect data format" };
  }
};

const LoadAndSave = ({ ready, name, setname, data, type }) => {
  const [readable, setIsReadable] = useState();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleNameChange = (e) => {
    setname(e.target.value);
  };

  const handleDownload = () => {
    const rawJson = jsonifyData(data);
    rawJson["project"] = { name, date: new Date(), type };
    const json = JSON.stringify(rawJson);
    const file = new Blob([json], { type: "application/json" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = name + ".json";
    a.click();
  };

  const handleLoad = () => {
    setIsLoadingFile(true);
    setErrorMessage("");
  };

  const loadFileData = (file, fName) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = JSON.parse(e.target.result);
      const pType = get(fileData, `project.type`);
      const isValid = pType === type;
      if (!isValid) {
        setErrorMessage("Error: Invalid project file. Try Again.");
      } else {
        setErrorMessage("");
  
        Object.keys(fileData).forEach((key, i) => {
          if (key !== "project") {
       
            const stream = data[i];
            Object.keys(stream).forEach((k) => {
              if (key !== k) {
                stream[k](fileData[key]);
              }
            });
          }
        });
        setIsLoadingFile(false);
      }
    };
    reader.readAsText(file);
  };

  const handleLoadFile = (e) => {
    const filename = get(e, `target.files[0].name`, "");
    const loadedFile = e.target.files[0];
    setname(() => {
      const fName = filename.replace(".json", "");
      if (fName !== "") {
        loadFileData(loadedFile, fName);
      }
      return fName;
    });
  };

  const handleSubmit = (e) => e.preventDefault();

  useEffect(() => {
    if (typeof window.FileReader === "function") {
      setIsReadable(true);
    }
  }, []);
  return readable ? (
    <NotPrintable>
      <AltStyledForm onSubmit={handleSubmit} style={{ margin: "8vh 0 0 0" }}>
        <AltStyledForm.Group>
          <Type.FormLabel>Project Name</Type.FormLabel>
          <AltStyledForm.Control
            as="input"
            value={name}
            onChange={handleNameChange}></AltStyledForm.Control>
          <Row>
            <Col>
              {!isLoadingFile ? (
                <CalculatorButton onClick={handleLoad}>Load</CalculatorButton>
              ) : (
                <>
                  <AltStyledForm.File
                    type="file"
                    accept=".json"
                    onChange={handleLoadFile}
                    //custom
                    style={{ paddingTop: `1rem` }}
                  />
                  {errorMessage !== "" ? (
                    <AltStyledForm.Label>{errorMessage}</AltStyledForm.Label>
                  ) : undefined}
                </>
              )}
            </Col>
            <Col>
              <CalculatorButton
                onClick={handleDownload}
                disabled={
                  ready && name && !isLoadingFile !== "" ? undefined : true
                }>
                Save
              </CalculatorButton>
            </Col>
          </Row>
        </AltStyledForm.Group>
      </AltStyledForm>
    </NotPrintable>
  ) : null;
};

export default LoadAndSave;
