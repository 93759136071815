import React from "react";

export const Header = React.forwardRef(({ children, className, style }, ref) => {
  //   style["top"] = offset ? offset : 0;
  return (
    <header
      className={className}
      style={style}
      ref={ref}
    >
      {children}
    </header>
  );
});
