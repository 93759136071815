import * as React from "react";
import { colors } from "../../../../vars/palette";
import { IconProps } from "./ShelfIconCalc";

export const ShelfIconVideo = ({ color }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32">
    <path
      id="Icon_material-video-library"
      data-name="Icon material-video-library"
      d="M6.2,9.4H3V31.8A3.209,3.209,0,0,0,6.2,35H28.6V31.8H6.2ZM31.8,3H12.6A3.209,3.209,0,0,0,9.4,6.2V25.4a3.209,3.209,0,0,0,3.2,3.2H31.8A3.209,3.209,0,0,0,35,25.4V6.2A3.209,3.209,0,0,0,31.8,3ZM19,23V8.6l9.6,7.2Z"
      transform="translate(-3 -3)"
      fill={color ? color : colors.blue.hex}
    />
  </svg>
);
