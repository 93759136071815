import get from "lodash/get";
import { PdfAssetFields } from "../fragments/NodePdfAssetFields";
import { slugifyTitle, Tag } from "wmk-tag";
import { DocumentFields } from "../fragments/NodeDocumentFields";

/**
 */
const titleify = (tag: string) => {
  return tag.replace(/-/g, " ").trim();
};
/**
 */
export const tagify = (description: string) => {
  const split = typeof description === "string" ? description.split(",") : [];
  const tags: Tag[] = Array.isArray(split)
    ? split.reduce((all, tag) => {
        all.push(new Tag({ name: titleify(tag), slug: slugifyTitle(tag) }));
        return all;
      }, [])
    : [];
  return tags;
};
const sortByDate = (a: PdfAsset, b: PdfAsset) =>
  b.date.getTime() - a.date.getTime();

export class PdfAsset {
  title: string;
  file?: PdfFile;
  description: string;
  tags: Tag[];
  timestamp: string;
  date: Date;
  keywords: string[];
  download: string;
  constructor(node: PdfAssetFields | DocumentFields) {
    const desc =
      node.__typename === "ContentfulAsset"
        ? node.description
        : node.shelfSlugs?.shelfSlugs
        ? node.shelfSlugs.shelfSlugs
        : "";
    const timestamp =
      node.__typename === "ContentfulAsset"
        ? node.timestamp
        : node.file.timestamp;
    this.title = node.title;
    this.file =
      node.__typename === "ContentfulAsset"
        ? new PdfFile(node)
        : new PdfFile({
            ...node.file,
            __typename: "ContentfulAsset",
            description: desc,
            title: node.title
          });
    this.description = desc;
    this.tags = tagify(desc);
    this.timestamp = timestamp;
    this.date = new Date(timestamp);
    this.keywords =
      node.__typename === "ContentfulDocument"
        ? Array.isArray(node.keywords)
          ? node.keywords
          : []
        : [];
    this.download =
      node.__typename === "ContentfulAsset"
        ? `/docs/${slugifyTitle(node.title)}`
        : `/docs/${node.slug}`;
  }
}

export class PdfAssets {
  list: PdfAsset[];
  length: number;
  tagHash: { [key: string]: PdfAsset[] };
  constructor(edges: ({ node: PdfAssetFields } | { node: DocumentFields })[]) {
    const assets = Array.isArray(edges)
      ? edges
          .reduce((filtered, asset) => {
            const desc =
              asset.node.__typename === "ContentfulAsset"
                ? asset.node.description
                : asset.node.shelfSlugs?.shelfSlugs
                ? asset.node.shelfSlugs.shelfSlugs
                : "";
            if (desc !== "") {
              filtered.push(new PdfAsset(asset.node));
            }
            return filtered;
          }, [])
          // remove duplicate titles
          .filter((value, index, self) => {
            return (
              index ===
              self.findIndex((t) => {
                return slugifyTitle(t.title) === slugifyTitle(value.title);
              })
            );
          })
      : [];
    this.list = assets.sort(sortByDate);
    this.length = assets.length;
    const tags = assets.reduce((all, pdf) => {
      pdf.tags.forEach((tag: Tag) => {
        const { name, slug } = tag;
        if (!all[slug]) {
          all[slug] = { slug, name, list: [] };
        }
        const list = all[slug].list;
        list.push(pdf);
      });

      return all;
    }, {});
    this.tagHash = tags;
  }
  getMatch(slug: string) {
    const tagFromSlug = slug.replace("/", "");
    const hash = get(this, `tagHash[${tagFromSlug}]`);
    const list = get(hash, `list`);
    const match = Array.isArray(list) ? hash.list : [];
    return match;
  }
}

export class PdfFile {
  fileType: string;
  url: string;
  fileName?: string;
  description?: string;
  constructor(asset: PdfAssetFields) {
    this.fileType = asset.mimeType;
    this.url = asset.url;
    this.fileName = asset.description;
    this.description = asset.description;
  }
}
