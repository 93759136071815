import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MAINBAR_MOBILE_BREAK } from "../../../Header";
import get from "lodash/get";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../../../vars/palette";
import styled from "styled-components";
import { getSubscribeText } from "../../../TopBar";

const TopLink = styled(Col)`
  a {
    color: ${colors.darkGray.hex};
    &:hover {
      text-decoration: underline;
      color: ${colors.white.hex};
    }
  }
`;

const TopLinksDrawer = ({ viewWidth, isBurgerOpen, topbarMenu }) => {
  const topLinks = get(topbarMenu, `links`);
  const isBurger = viewWidth < MAINBAR_MOBILE_BREAK;
  return isBurger && isBurgerOpen && Array.isArray(topLinks) ? (
    <Container
      fluid={isBurger ? true : undefined}
      style={isBurger ? { padding: "0 3vw" } : undefined}>
      <Row className="flex-column">
        {topLinks.map((item, i) => {
          const to = get(item, `to`);
          const text = getSubscribeText(get(item, `text`));
          const target = get(item, `target`);
          return (
            <TopLink
              key={text + i}
              style={{
                padding: `.5rem 0`,
                textTransform: "uppercase",
                fontSize: `.9rem`
              }}>
              <WmkLink to={to} target={target}>
                {text}
              </WmkLink>
            </TopLink>
          );
        })}
      </Row>
    </Container>
  ) : null;
};

export default TopLinksDrawer;
